import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store";
import apiClient from "../../utils/axiosInstance";
import {
  deleteUserLimitedChat,
  starredThread,
  updateChatNotification,
  updateThreadNotificatioOnOrOff,
} from "../../store/finChat";
import { Icon } from "@iconify/react/dist/iconify.js";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import Modal from "../Modal";
import { IoAdd } from "react-icons/io5";
import chatIcon from "../../assets/chat_icon.png";
import { RiDeleteBin6Line } from "react-icons/ri";

function SidebarRenderThreads({ isItemActive, setActiveItemId }) {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { chatWithLimitState, organizationState } = useAppSelector((state) => ({
    chatWithLimitState: state.finChat.chatWithLimit,
    organizationState: state.organization.organization,
  }));
  const [showButtons, setShowButtons] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [discardedThreadId, setDiscardedThreadId] = useState(null);
  const [isNotificationOn, setIsNotificationOn] = useState(false);
  const [isThreadDiscardLoading, setIsThreadDiscardLoading] = useState(false);

  const handleItemClick = (itemId) => {
    setActiveItemId(itemId);
  };

  useEffect(() => {
    if (
      location?.state?.isFirstTime &&
      chatWithLimitState?.history?.length > 0
    ) {
      const thread = chatWithLimitState?.history[0];
      setActiveItemId(thread.threadId);
      if (thread.type === "chat") {
        navigate(`/answer-search?id=${thread.threadId}`);
      } else {
        navigate(`/thread?id=${thread.threadId}`);
      }
    }
  }, [location?.state || chatWithLimitState?.history]);

  const resetThreadChatsNotification = async (threadId) => {
    try {
      await apiClient().put(`/reset-notification/${threadId}`);
      dispatch(updateChatNotification({ threadId: threadId, notification: 0 }));
    } catch (error) {
      console.error("Error resetting notification:", error);
    }
  };

  const handleStarredThread = async (threadId) => {
    try {
      dispatch(starredThread(threadId));
      await apiClient().put(`/thread-starred-discarded/${threadId}`, {
        field: "starred",
      });
    } catch (error) {
      console.error("Error starring thread:", error);
    }
  };

  const discardedThread = async () => {
    try {
      await apiClient().put(`/thread-starred-discarded/${discardedThreadId}`, {
        field: "discarded",
      });
      dispatch(deleteUserLimitedChat(discardedThreadId));
      setIsThreadDiscardLoading(false);
    setShowModal(false);

    
    } catch (error) {
      console.error("Error discarding thread:", error);
    }
  };

  const handleChangeNotification = async (item) => {
    setIsNotificationOn((prev) => !prev);

    try {
      const response = await apiClient().put(
        `/thread-starred-discarded/${item.threadId}`,
        {
          field: item.notificationOn ? "notificationOff" : "notificationOn",
        }
      );
      console.log(
        "response notificationOn:",
        response.data.thread.notificationOn
      );
      const payload = {
        threadId: item.threadId,
        notificationOn: response.data.thread.notificationOn,
      };

      dispatch(updateThreadNotificatioOnOrOff(payload));
    } catch (error) {
      console.error("Error discarding thread:", error);
    }
  };

  const handleDiscarded = (threadId) => {
   
    setShowModal(true);
    setDiscardedThreadId(threadId);
  };

  const confirmDiscardedThread = () => {
    setIsThreadDiscardLoading(true);
    discardedThread();
    setShowButtons(false);
  };

  return (
    <>
    <div className="px-3 py-1 flex flex-col max-h-[calc(100vh-56px)]">
      <div className="mb-2">
        <div className="flex items-center justify-between py-5 border-b-2">
          <p className="text-xl font-bold">Threads</p>
          <button onClick={() => navigate("/dashboard")}>
            <Icon
              icon="heroicons:pencil-square"
              width="1.3em"
              height="1.3em"
              className="text-secondary"
            />
          </button>
        </div>
      </div>
      <ul className="space-y-2  hover:overflow-y-auto overflow-hidden ">
        {chatWithLimitState?.history?.slice(0, 10).map((item, index) => (
          <li
            key={index}
            className={`text-sm flex items-center p-1 rounded-lg transition-colors duration-200 hover:bg-slate-200`}
            style={{
              background: isItemActive(item.threadId)
                ? organizationState[0]?.secondaryColor
                : "",
              color: isItemActive(item.threadId) ? "white" : "black",
            }}
          >
            <div
              className="relative group w-full"
              onMouseEnter={() => setShowButtons(index)}
              onMouseLeave={() => setShowButtons(false)}
            >
              <Link
                to={
                  item.type === "chat"
                    ? `/answer-search?id=${item.threadId}`
                    : `/thread?id=${item?.threadId}`
                }
                className={`truncate w-full flex items-center  capitalize font-normal ${
                  item.notification > 0 ? "text-gray-900 font-semibold" : ""
                }`}
                onClick={(e) => {
                  handleItemClick(item.threadId);
                  if (item.notification > 0) {
                    e.preventDefault();
                    resetThreadChatsNotification(item.threadId).then(() => {
                      const path =
                        item.type === "chat"
                          ? `/answer-search?id=${item.threadId}`
                          : `/thread?id=${item?.threadId}`;
                      navigate(path);
                    });
                  }
                }}
                title={item.chatName}
              >
                {item.type === "thread" ? (
                  <Icon
                    icon="heroicons:chat-bubble-left-ellipsis"
                    width="18px"
                    height="18px"
                    className="text-pricingText mr-2"
                    style={{
                      color: isItemActive(item.threadId) ? "white" : "black",
                    }}
                  />
                ) : (
                  <div className="w-[18px] h-[18px] relative flex items-center justify-center mr-2">
                    <img
                      src={chatIcon}
                      alt="chat"
                      width="24px"
                      height="24px"
                      className={`min-w-[24px] min-h-[24px] ${
                        isItemActive(item.id) ? "brightness-0 invert" : ""
                      }`}
                    />
                  </div>
                )}
                <div className="max-w-[78%] truncate">{item.chatName}</div>

                {item.notification > 0 && (
                  <div className="bg-red-500 text-white text-xs font-bold w-5 h-5 p-[1.5px] rounded-full ml-2 text-center">
                    {item.notification > 4 ? "4+" : item.notification}
                  </div>
                )}
              </Link>
              {showButtons === index && (
                <div
                  className={`absolute right-0 top-1/2 -translate-y-1/2 flex space-x-2 rounded-md px-2 py-1 transition-opacity bg-white duration-200 scale-90`}
                >
                  <button
                    className="rounded"
                    style={{
                      color: organizationState[0]?.secondaryColor,
                    }}
                    onClick={() => {
                      handleStarredThread(item.threadId);
                    }}
                  >
                    {item.starred ? (
                      <Icon icon="mdi:star" width="1.5em" height="1.5em" />
                    ) : (
                      <Icon
                        icon="mdi:star-outline"
                        width="1.5em"
                        height="1.5em"
                      />
                    )}
                  </button>
                  <button
                    className="rounded "
                    style={{
                      color: organizationState[0]?.secondaryColor,
                    }}
                    onClick={() => handleChangeNotification(item)}
                  >
                    {item.notificationOn ? (
                      <Icon
                        icon="basil:notification-on-outline"
                        width="1.2em"
                        height="1.2em"
                      />
                    ) : (
                      <Icon
                        icon="iconamoon:notification-off"
                        width="1.2em"
                        height="1.2em"
                      />
                    )}
                  </button>

                  <button
                    className="rounded "
                    style={{
                      color: organizationState[0]?.secondaryColor,
                    }}
                    onClick={() => handleDiscarded(item.threadId)}
                  >
                    <RiDeleteBin6Line size={19} />
                  </button>
                </div>
              )}
            </div>
          </li>
        ))}
      </ul>

      {chatWithLimitState?.history?.length > 0 && (
        <button
          className=" font-normal  text-center ml-2 border-b-2 w-[70px] m-2"
          style={{
            color: organizationState[0]?.primaryColor,
            borderColor: organizationState[0]?.primaryColor,
          }}
          onClick={() => navigate("/chat-history")}
        >
          View All
        </button>
      )}
      <div className="flex items-center gap-2 py-1 rounded hover:bg-slate-200 cursor-pointer" onClick={() => {
            navigate(`/dashboard`);
          }}>
        <div
          className="flex items-center justify-center p-1 rounded-md"
          style={{ backgroundColor: "#e5e7eb" }}
        >
          <IoAdd />
        </div>
        <button
          className="text-center text-sm"
          
        >
          Create new Thread
        </button>
      </div>
    </div>

    <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        confirmAction={confirmDiscardedThread}
        label={"Discard Thread"}
        description={
          "Are you sure you want to discard this thread? This action cannot be undone and the thread will be permanently removed from your account."
        }
        confirmText={isThreadDiscardLoading ? (<span className="loader"></span>) : "Confirm"}
        cancelText={"Cancel"}
      />
    </>
  );
}

export default SidebarRenderThreads;
