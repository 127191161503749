import { Icon } from "@iconify/react";
import { useEffect, useRef, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { useAppDispatch, useAppSelector } from "../store";
import { getFolders } from "../store/DocumentSlice";
import SpinnerLoading from "./SpinnerLoading";
import { FilesPreview, ChatFileUpload } from "./Helper";
import { EditorState, Modifier } from "draft-js";
import apiClient from "../utils/axiosInstance";
import { addNewChat, getUserChatsWithinLimit, getDirectMessages } from "../store/finChat";
import useAuth from "../utils/useAuth";
import posthog from "posthog-js";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { useSocket } from "../context/SocketProvider";
import WebChatModel from "./WebChatModel";
import LinkPreviewGrid from "./LinkPreviewGrid";
import opanAilogo from "../assets/openai-logo.webp";
import geminiLogo from "../assets/Gemini2.webp";
import anthropicLogo from "../assets/anthorpic.jpeg";
import placeLogo from "../assets/logo-1.png";
import { CiGlobe } from "react-icons/ci";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { addChat, addNewDirectMessage, setFetchDms } from "../store/ThreadSlice";
import { convertToRaw } from 'draft-js';
import { LuBookMarked } from "react-icons/lu";
import PromptLibraryPopup from "./PromptPopup";
import ChatLoader from "./ChatLoader";

const insertMention = (editorState, mention) => {
  const contentState = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();

  const mentionText = `${mention.text} `;
  const contentStateWithEntity = contentState.createEntity(
    "MENTION",
    "IMMUTABLE",
    { mention }
  );

  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

  const contentStateWithMention = Modifier.replaceText(
    contentState,
    selectionState,
    mentionText,
    null,
    entityKey
  );

  const newEditorState = EditorState.push(
    editorState,
    contentStateWithMention,
    "insert-characters"
  );

  return EditorState.forceSelection(
    newEditorState,
    contentStateWithMention.getSelectionAfter()
  );
};

const MessageEditor = ({
  selectedFiles,
  organization,
  isAnotherspace = false,
  setSelectedFiles,
  uploadingFiles,
  setUploadingFiles,
  tags,
  setTags,
  handleSendMessage,
  isApiCalling,
  setIsApiCalling,
  chatApiCalling,
  setChatApiCalling,
  isLoading,
  mentionState,
  setMentionState,
  taggedFolders,
  setTaggedFolders,
  editorState,
  setEditorState,
  sharedFolders,
  tab,
  isReplying = false,
  memberId,
  setCurrentModel
}) => {
  const { user } = useAuth();
  const { socket } = useSocket();
  const selectedFilesRef = useRef(selectedFiles);
  selectedFilesRef.current = selectedFiles;
  const { folderState, status, chats, modelState, userDetailsState, isPlanActive } =
    useAppSelector((state) => ({
      chats: state.finChat.chatWithLimit,
      modelState: state.aiModels.aiModels,
      userDetailsState: state.auth.userDetails,
      folderState: state.document.folders,
      status: state.document.folderStatus,
      isPlanActive: state.organization.isPlanActive,
    }));
  const thread_id = useAppSelector((state) => state.thread?.threadChats[0]?.threadId);
  const navigate = useNavigate();
  let folders = [];
  const [detectedLinks, setDetectedLinks] = useState([]);
  const [closedLinks, setClosedLinks] = useState([]);
  const [selectedOption, setSelectedOption] = useState(["auto"]);
  const [isOpen, setIsOpen] = useState(false);
  const [link, setLink] = useState({ isLink: 0, url: "" });
  const [isWebChatOpen, setIsWebChatOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const filesPreviewRef = useRef(null);
  const linkPreviewRef = useRef(null);
  const [buttonTopPosition, setButtonTopPosition] = useState(0);

  const [defaultModel, setDefaultModel] = useState(null);
  const [allModels, setAllModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState(null);

  const [isWebSearched, setIsWebSearched] = useState(false);
  const [disableModel, setDisableModel] = useState(false);

  const { organizationState } = useAppSelector((state) => ({
    organizationState: state.organization.organization,
  }));

  const [isPromptPopupOpen, setIsPromptPopupOpen] = useState(false);
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  useEffect(() => {
    if (selectedOption === "webchat") {
      setIsOpen(true);
    }
  }, [selectedOption]);

  useEffect(() => {
    if (isWebSearched) {
      setSelectedModel(allModels.find(model => model.model === "gemini-2.0-flash-exp"));
      setDisableModel(true);
    } else {
      setDisableModel(false);
    }
  }, [isWebSearched]);

  useEffect(() => {
    if (modelState) {
      const filteredProviders = modelState?.providers?.filter(
        provider => provider.provider_name !== "pinecone" && provider.provider_name !== "exa"
      );

      let models = [];
      filteredProviders?.forEach((provider) => {
        if (!provider.provider_key) return;
        const providerModels = modelState.models[provider.modelId]?.map((model) => {
          const newModel = {
            ...model,
            provider_name: provider.provider_name,
            provider_key: provider.provider_key,
            providerId: provider.modelId,
          };
          if (provider.currentUsed && model.currentUsed) {
            setDefaultModel(newModel);
            setSelectedModel(newModel);
          }
          return newModel;
        });
        models = models.concat(providerModels);
      });
      setAllModels(models);
    }
  }, [modelState])

  const mapFolders = (folderArray) =>
    folderArray?.map((folder) => ({
      folderName: folder.folderName,
      folderId: folder.folderId,
    })) || [];

  folders = (() => {
    switch (tab) {
      case "chat":
        return tags.length > 0
          ? mapFolders(sharedFolders)
          : mapFolders(folderState?.folders);
      case "thread":
        if (tags?.length === 0) return mapFolders(folderState?.folders);
        return sharedFolders?.length > 0 ? mapFolders(sharedFolders) : [];
      default:
        return sharedFolders?.length > 0 ? mapFolders(sharedFolders) : [];
    }
  })();

  const dispatch = useAppDispatch();

  useEffect(() => {
    setEditorState((prevState) => {
      const contentState = prevState.getCurrentContent();
      const selection = prevState.getSelection();
      const mentionEntity = contentState.createEntity("MENTION", "IMMUTABLE", {
        mention: { name: `@${organization?.chatBotName || "chatbot"}` },
      });
      const entityKey = mentionEntity.getLastCreatedEntityKey();
      const mentionText = `@${organization?.chatBotName}`;
      const contentStateWithEntity = Modifier.replaceText(
        contentState,
        selection.merge({
          anchorOffset: 0,
          focusOffset: contentState.getPlainText().length,
        }),
        null,
        entityKey
      );

      const newEditorState = EditorState.push(
        prevState,
        contentStateWithEntity,
        "insert-characters"
      );
      return EditorState.moveFocusToEnd(newEditorState);
    });
  }, [organization]);

  useEffect(() => {
    if (!folderState?.folders?.length > 0) {
      dispatch(getFolders());
    }
  }, [dispatch, folderState?.folders?.length]);

  useEffect(() => {
   setCurrentModel?.(selectedModel);
  }, [selectedModel]);

  const handleChange = (newEditorState) => {
    setEditorState(newEditorState);
    const content = newEditorState.getCurrentContent();
    const rawText = content.getPlainText();
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const links = rawText.match(urlRegex) || [];
    setDetectedLinks(links);
  };

  const handleReturn = (e) => {
    console.log("handle return called");
    if (!isPlanActive) {
      toast.error("Please upgrade your plan to use this feature");
      return;
    }
    setDetectedLinks([]);
    if (isAnotherspace) {
      return "handled";
    }
    const isEnterPress = e && e.key === "Enter" && !e.shiftKey;
    const selectionState = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const blockKey = selectionState.getStartKey();
    const block = contentState.getBlockForKey(blockKey);
    const textBeforeCursor = block
      .getText()
      .slice(0, selectionState.getStartOffset());

    const lastCharacter = textBeforeCursor.slice(-1);
    const lastWord = textBeforeCursor.slice(
      textBeforeCursor.lastIndexOf(" ") + 1
    );
    const str = "@" + organizationState[0]?.chatBotName;
    if (isEnterPress && lastWord[0] === "@" && lastWord.length > 1 && lastWord !== str) {
      return "handled";
    }
    if (isEnterPress && lastCharacter === "@") {
      const mentionList = getSuggestions();
      if (mentionList.length > 0) {
        const firstMention = mentionList[0];
        const newEditorState = insertMention(editorState, firstMention);
        const contentState = newEditorState.getCurrentContent();
        const plainText = contentState.getPlainText();

        const newTaggedFolders = folders
          ?.filter((folder) => plainText.includes(`@${folder.folderName}`))
          .map((folder) => folder.folderId);

        setTaggedFolders(newTaggedFolders);
        setEditorState(newEditorState);
        return "handled";
      }
    } else if (isEnterPress) {

      if (isWebSearched) {
        handleDireactWebSearch();
        return "handled";
      }
      const contentState = editorState.getCurrentContent();
      const hasText = contentState.getPlainText().trim();
      const hasFiles = selectedFiles.length > 0;

      if (hasText.trim() || hasFiles) {
        const contentState = editorState.getCurrentContent();
        const plainText = contentState.getPlainText();
        const containsAskfinAI = plainText.includes(
          `@${organization?.chatBotName}`
        );
        const messageText = plainText
          .replace(`@${organization?.chatBotName}`, "")
          .trim();
        if (
          messageText.trim() !== "" &&
          tags?.length === 0 &&
          tab === "chat" &&
          taggedFolders.length === 0
        ) {
          askfinThread(messageText);
          setSelectedFiles([]);
          // setEditorState(EditorState.createEmpty());
          return "handled";
        } else if (messageText.trim() !== "" && tab === "chat") {
          normalThread(messageText, hasFiles, containsAskfinAI);
          setSelectedFiles([]);
          setEditorState(EditorState.createEmpty());
          return "handled";
        } else if (messageText.trim() !== "" && tab !== "chat") {
          handleSendMessage(editorState, selectedModel);
          setSelectedFiles([]);
          setEditorState(EditorState.createEmpty());
          return "handled";
        }
      }
    }

    return "not-handled";
  };

  const askfinThread = async (messageText) => {
    try {
      if(isLoading){
        return;
      }
      setLoading(true);
      setChatApiCalling?.(true);
      if (!organization.isAiChatUsed) {
        toast.error("AI chat feature is currently disabled!");
        return;
      }

      if (!selectedModel) {
        toast.error("Provide a API key for atleast one model");
        return;
      }


      let body = {
        query: messageText.replace(`@${organization?.chatBotName}`, "").trim(),
        action: "chat",
        createdAt: new Date().toISOString(),
        provider_model: selectedModel.model,
        provider_key: selectedModel.provider_key,
        provider_name: selectedModel.provider_name
      };

      const response = await apiClient().post(`/chat`, body);
      dispatch(addNewChat(response.data));

      if (response && chats.length === 0 && user?.email) {
        posthog.identify(user?.email);
        posthog.capture("chat_started", { email: user?.email });
      }

      const threadId = response?.data?.threadId;
      if (threadId) {
        navigate(`/answer-search?id=${threadId}&tabType=dashboard`);
      }
    } catch (error) {
      toast.error(error);
      console.log(error);
    } finally {
      setChatApiCalling?.(false);
      setLoading(false);
    }
  };

  const normalThread = async (messageText, hasFiles, containsAskfinAI) => {
    try {
      setIsApiCalling(true);

      const taggedMembers = Object.values(
        tags
          .filter((tag) => tag.type === "member")
          .reduce((acc, tag) => {
            acc[tag.id] = tag;
            return acc;
          }, {})
      );
      const taggedTeams = Object.values(
        tags
          .filter((tag) => tag.type === "team")
          .reduce((acc, tag) => {
            acc[tag.id] = tag;
            return acc;
          }, {})
      );
      const tagged = [...taggedMembers, ...taggedTeams];
      const sharedWith = tagged.map((tag) => ({
        tagId: tag.id,
        accountType: tag.type,
      }));

      const messageType = hasFiles
        ? messageText
          ? "text-file"
          : "file"
        : "text";
      const markdownContent = convertEditorToMarkdown(editorState);
      const payload = {
        threadId: uuidv4(),
        messageId: uuidv4(),
        isThread: true,
        message: markdownContent,
        title: messageText.length > 25 ? messageText.slice(0, 25) + "..." : messageText,
        tag: sharedWith,
        createdAt: new Date().toISOString(),
        messageType: messageType,
      };

      if (hasFiles) {
        payload.sharedFiles = selectedFiles.map((file) => ({
          docS3Loc: file.docS3Loc,
          url: file.url,
          fileName: file.file.name,
        }));
      }

      setEditorState(EditorState.createEmpty());
      console.log("🚀 ~ normalThread ~ payload:", payload)
      const { data } = await apiClient().post("/thread", payload);
      if (data.is_direct_message) {
        dispatch(getDirectMessages());
        navigate(`/dm?id=${data.dm.receiverId}&tabType=dashboard`);
      } else if (data.is_team_message) {
        navigate(`/team?id=${data.team.tag[0].tagId}&tabType=dashboard`);
      } else {
        dispatch(addNewChat(data.threadChat));
        navigate(`/thread?id=${data.threadChat.threadId}&tabType=dashboard`, {
          state: {
            chat: data.threadChat,
          },
        });
        socket.emit("new_main_thread_created", {
          tag: tagged.map((tag) => ({
            tagId: tag.id,
            accountType: tag.type,
          })),
          thread: data.threadChat,
        });
      }

    } catch (error) {
      toast.error(error);
      console.log(error);
    } finally {
      setIsApiCalling(false);
      setTags([]);
    }
  };

  const handleMentionChange = (editorState) => {
    const contentState = editorState.getCurrentContent();
    const plainText = contentState.getPlainText();
    const askFinTagged = plainText.includes(`@${organization?.chatBotName}`);

    const newTaggedFolders = folders
      ?.filter((folder) => plainText.includes(`@${folder.folderName}`))
      .map((folder) => folder.folderId);

    setTaggedFolders(newTaggedFolders);

    setMentionState((prev) => ({
      ...prev,
      showAskFin: !askFinTagged,
      askFinTagged: askFinTagged,
    }));

    getSuggestions(newTaggedFolders);
  };

  const getSuggestions = (currentTaggedFolders) => {
    if (mentionState.showAskFin) {
      return [
        { text: organization?.chatBotName, value: organization?.chatBotName },
      ];
    }
    const uniqueFolders = folders?.reduce((acc, current) => {
      const x = acc.find((item) => item.folderId === current.folderId);
      if (!x) {
        acc.push(current);
      }
      return acc;
    }, []);
    return uniqueFolders
      ?.filter((folder) => !currentTaggedFolders?.includes(folder.folderId))
      .map((folder) => ({
        text: folder.folderName,
        value: folder.folderName,
      }));
  };

  const handleDireactWebSearch = async () => {
    try {
      setLoading(true);
      const contentState = editorState.getCurrentContent();
      const hasText = contentState.getPlainText().trim();
      if (!hasText) {
        return toast.error("");
      }

      const basePayload = {
        action: "webchat",
        query: hasText,
        createdAt: new Date().toISOString(),
      };

      let payload;
      if (tab === "dm") {
        payload = {
          ...basePayload,
          is_dm_chat: true,
          receiverId: memberId
        };
      } else if (tab === "team") {
        payload = {
          ...basePayload,
          is_space_chat: true,
          threadId: thread_id
        };
      } else {
        payload = {
          ...basePayload,
        };
      }

      const response = await apiClient().post("/chat", payload);
      if (tab === "dm") {
        dispatch(addNewDirectMessage(response.data))
        setEditorState(EditorState.createEmpty());
        socket.emit("direct_message", response.data);
      } else if (tab === "team") {
        dispatch(addChat(response.data));
        setEditorState(EditorState.createEmpty());
      } else {
        setEditorState(EditorState.createEmpty());
        // resetForm();
        // setIsOpenWebModal(false);

        const threadId = response?.data?.threadId;
        if (threadId) {
          navigate(`/answer-search?id=${threadId}&tabType=dashboard`);
          dispatch(getUserChatsWithinLimit(15));
          setLoading(false);
        }
      }

      setLoading(false);
    } catch (error) {
      console.log("Error in Direact Web Search ", error);
      setLoading(false);
      toast.error(error);
    } finally {
      // setSubmitting(false);
      // setIsOpenWebModal(false);
      setLoading(false);
    }
  };

  const updateButtonPosition = () => {
    const filesPreviewHeight = filesPreviewRef.current?.offsetHeight || 0;
    const linkPreviewHeight = linkPreviewRef.current?.offsetHeight || 0;
    let newButtonTopPosition = 0;
    if (filesPreviewHeight === 0) {
      newButtonTopPosition = filesPreviewHeight + linkPreviewHeight + 22;
    } else {
      newButtonTopPosition = filesPreviewHeight + linkPreviewHeight + 22 + 8;
    }
    setButtonTopPosition(newButtonTopPosition);
  };

  useEffect(() => {
    updateButtonPosition();
  }, [selectedFiles, link]);

  useEffect(() => {
    if (selectedPrompt?.prompt) {
      let newEditorState = EditorState.createEmpty();

      const contentState = newEditorState.getCurrentContent();
      const promptText = selectedPrompt.prompt + "\n\n";
      const newContentState = Modifier.insertText(
        contentState,
        contentState.getSelectionAfter(),
        promptText
      );

      newEditorState = EditorState.push(
        newEditorState,
        newContentState,
        'insert-characters'
      );

      newEditorState = EditorState.moveFocusToEnd(newEditorState);

      setEditorState(newEditorState);
      setIsPromptPopupOpen(false);
    }
  }, [selectedPrompt]);

  return (
    <>
      {(isLoading || status === "loading") && (
        <SpinnerLoading isLoading={true} />
      )}
      {userDetailsState && (
        <div
          className={`bg-white ${tab !== "chat" &&
            `${!isReplying && "xl:px-5"} lg:px-4 px-2 py-2 lg:py-4`
            }`}
        >
          {((mentionState.askFinTagged && isApiCalling) || (loading && isWebSearched)) && (
        <ChatLoader />
      )}
          <div className={`relative border-2 rounded-lg `}>
            <div className="max-h-[300px] min-h-[100px] mb-2 relative">
              {(selectedModel || defaultModel) &&
                <div className="absolute flex items-center z-50 justify-between" style={{ top: `${buttonTopPosition}px`, transform: "translateY(-50%)", right: "10px" }}>
                  <DropdownWithLogos
                    defaultModel={defaultModel}
                    allModels={allModels}
                    selectedModel={selectedModel}
                    setSelectedModel={setSelectedModel}
                    disableModel={disableModel}
                  />
                </div>}
              {organizationState[0]?.isWebsearchUsed &&
                <div className="absolute flex items-center  justify-between" style={{ top: `${buttonTopPosition}px`, transform: "translateY(-50%)", left: "270px" }}>
                  <button
                    onClick={() => setIsWebSearched(!isWebSearched)}
                    className={`flex items-center rounded-full ${isWebSearched ? "bg-blue-100 text-blue-500" : ""
                      } transition-colors duration-300 hover:bg-blue-200 p-1`}
                  >
                    <CiGlobe size={20} />
                    {isWebSearched && <span className="mx-1 text-sm">Search</span>}
                  </button>
                </div>}
              <div ref={filesPreviewRef}>
                <FilesPreview
                  selectedFiles={selectedFiles}
                  setSelectedFiles={setSelectedFiles}
                  uploadingFiles={uploadingFiles}
                />
              </div>
              {link.url && (
                <div ref={linkPreviewRef}>
                  <a
                    href={link.url}
                    target="_blank"
                    className="text-sm text-blue-700 p-2"
                  >
                    {link?.url}
                  </a>
                </div>
              )}
              <Editor
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName  h-full"
                onEditorStateChange={(editorState) => {
                  handleChange(editorState);
                  handleMentionChange(editorState);
                }}
                handleReturn={handleReturn}
                mention={{
                  separator: " ",
                  trigger: "@",
                  suggestions: getSuggestions(),
                  handleReturn: (suggestion, mentionState) => {
                    if (suggestion) {
                      const newEditorState =
                        mentionState.addMention(suggestion);
                      handleChange(newEditorState);
                      return "handled";
                    }
                    return "not-handled";
                  },
                }}
                toolbar={{
                  options: ["inline", "list", "emoji", "image", "link"],
                  inline: {
                    options: ["bold", "italic"],
                    className: "inline-style",
                    bold: { icon: "/bold.svg", className: "bold-button" },
                  },
                  list: {
                    options: ["unordered", "ordered"],
                    className: "inline-style",
                  },
                  image: {
                    component: () => (
                      <ChatFileUpload
                        setSelectedFiles={setSelectedFiles}
                        setUploadingFiles={setUploadingFiles}
                      />
                    ),
                  },
                  link: {
                    component: () => (
                      <>
                        <button
                          className="rdw-option-wrapper"
                          onClick={() => setIsPromptPopupOpen(true)}
                        >
                          <LuBookMarked className=" !text-[16px]" />
                        </button>
                      </>
                    ),
                  },
                }}
              />
              {detectedLinks.length > 0 && (
                <LinkPreviewGrid
                  urls={detectedLinks}
                  setUrls={setDetectedLinks}
                  closedLinks={closedLinks}
                  setClosedLinks={setClosedLinks}
                />
              )}
              {
                isPromptPopupOpen && (
                  <PromptLibraryPopup
                    selectedPrompt={selectedPrompt}
                    setSelectedPrompt={setSelectedPrompt}
                    setIsPromptPopupOpen={setIsPromptPopupOpen}
                  />
                )
              }
            </div>
            <div className="flex justify-between items-end px-4 py-2 relative bottom-0">
              <div className="flex items-center flex-wrap">
                {isOpen && (
                  <WebChatModel
                    isOpenWebModal={isOpen}
                    setIsOpenWebModal={setIsOpen}
                  />
                )}
                {isWebChatOpen && (
                  <EditorLinkModel
                    setIsOpenWebModal={setIsWebChatOpen}
                    setLink={setLink}
                    link={link}
                  />
                )}
              </div>
              <div className="flex-shrink-0">
                <button
                  className={`px-4 py-1 rounded-md ${isAnotherspace
                    ? "text-borderColor cursor-not-allowed"
                    : (!editorState.getCurrentContent().hasText() &&
                      selectedFiles.length === 0) ||
                      isApiCalling
                      ? "text-borderColor cursor-not-allowed"
                      : "text-white"
                    }`}
                  style={{
                    backgroundColor: isApiCalling || (!editorState.getCurrentContent().hasText() &&
                      selectedFiles.length === 0)
                      ? "gray"
                      : editorState.getCurrentContent().hasText()
                        ? organization?.primaryColor
                        : organization?.secondaryColor,
                  }}
                  disabled={
                    isAnotherspace ||
                    (!editorState.getCurrentContent().hasText() &&
                      selectedFiles.length === 0) ||
                    isApiCalling
                  }
                  onClick={(e) => {
                    console.log("button clicked")
                    e.stopPropagation();
                    if (!isPlanActive) {
                      toast.error(
                        "Please upgrade your plan to use this feature"
                      );
                      return;
                    }
                    if (!isAnotherspace) {
                      if (link.isLink === 1) {
                        handleDireactWebSearch();
                      } else {
                        handleReturn({ key: "Enter" });
                      }
                    }
                  }}
                >
                  {isAnotherspace ? (
                    <Icon icon="mdi:lock" width="1.5em" height="1.5em" />
                  ) : isApiCalling ? (
                    <Icon
                      icon="eos-icons:loading"
                      width="1.5em"
                      height="1.5em"
                    />
                  ) : (
                    <Icon
                      icon="mdi:paper-airplane"
                      width="1.5em"
                      height="1.5em"
                    />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default MessageEditor;

const EditorLinkModel = ({ setIsOpenWebModal, setLink, link }) => {
  const [url, setUrl] = useState("");
  const [validUrl, setValidUrl] = useState(false);
  const { organizationState } = useAppSelector((state) => ({
    organizationState: state.organization.organization,
  }));

  useEffect(() => {
    const regex = /^(https?):\/\/([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(\/.*)?$/;
    setValidUrl(regex.test(url));
  }, [url]);

  const handleUrlChange = (e) => {
    setUrl(e.target.value);
  };
  const handleSubmit = (url) => {
    setLink({
      isLink: 1,
      url: url,
    });
  };

  return (
    <>
      <div className="fixed pl-64 top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 z-50 flex justify-center items-center">
        <div className="p-4 md:p-8 rounded-lg w-[350px] md:w-[600px] bg-white">
          <div className="flex items-center justify-between">
            <h2 className="text-2xl font-bold mb-4">Web chat</h2>
            <button
              onClick={() => setIsOpenWebModal(false)}
              className="p-2 hover:bg-gray-100 rounded-full"
            >
              <Icon icon="iconamoon:close" width="1.5em" height="1.5em" />
            </button>
          </div>
          <div className="mb-4">
            <label
              htmlFor="url"
              className="block text-sm font-medium text-gray-700"
            >
              URL
            </label>
            <input
              type="url"
              id="url"
              name="url"
              defaultValue={link?.url || url}
              onChange={handleUrlChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
            {/* Show error message if URL is invalid */}
            {url && !validUrl && (
              <div className="text-red-500 text-sm mt-1">
                Please enter a valid URL starting with http:// or https://
              </div>
            )}
          </div>
          <button
            type="button"
            onClick={() => {
              handleSubmit(url);
              setIsOpenWebModal(false);
            }}
            className="w-full text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            style={{ backgroundColor: organizationState[0]?.primaryColor }}
            disabled={!validUrl}
          >
            Chat
          </button>
        </div>
      </div>
    </>
  );
};

const DropdownWithLogos = ({ defaultModel, allModels, selectedModel, setSelectedModel, disableModel }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  // Close dropdown if clicked outside
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => document.removeEventListener("mousedown", handleOutsideClick);
  }, []);

  const getModelLogo = (providerName) => {
    switch (providerName) {
      case "openai":
        return opanAilogo;
      case "gemini":
        return geminiLogo;
      case "anthropic":
        return anthropicLogo;
      default:
        return null;
    }
  };

  return (
    <div className="relative">
      <button
        className={`flex items-center gap-2 mr-1 ${!disableModel ? "hover-effect" : ""}`}
        style={{
          opacity: disableModel ? "0.5" : "1",
        }}
        onClick={() => setShowDropdown(!showDropdown)}
        disabled={disableModel}
      >
        {getModelLogo(selectedModel?.provider_name || defaultModel?.provider_name) && (
          <img
            src={getModelLogo(selectedModel?.provider_name || defaultModel?.provider_name)}
            alt={selectedModel?.provider_name || defaultModel?.provider_name}
            className="w-4 h-4 rounded-md"
          />
        )}
        {selectedModel?.model || defaultModel?.model}
        {showDropdown ? <MdKeyboardArrowUp style={{ fontSize: "20px" }} /> : <MdKeyboardArrowDown style={{ fontSize: "20px" }} />}
      </button>

      {showDropdown && (
        <div
          ref={dropdownRef}
          className="absolute bottom-[30px] right-0  mt-2 w-[250px] h-[250px] overflow-auto bg-white shadow-md rounded-md z-50"
        >
          {allModels.map((model, index) => {
            const logo = getModelLogo(model.provider_name);
            return (
              <div
                key={index}
                className={`flex items-center my-1 p-2 cursor-pointer ${selectedModel?.model === model.model ? "bg-slate-500 text-white" : "hover:bg-slate-200"
                  }`}
                onClick={() => {
                  setSelectedModel(model);
                  setShowDropdown(false);
                }}
              >
                {logo && <img src={logo} alt={model.provider_name} className="w-6 h-6 mr-3 rounded-md" />}
                <p>{model.model}</p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};


export const convertEditorToMarkdown = (editorState) => {
  const contentState = editorState.getCurrentContent();
  const raw = convertToRaw(contentState);

  let markdown = '';

  raw.blocks.forEach((block) => {
    // Handle block level formatting
    switch (block.type) {
      case 'header-one':
        markdown += `# ${block.text}\n\n`;
        break;
      case 'header-two':
        markdown += `## ${block.text}\n\n`;
        break;
      case 'unordered-list-item':
        markdown += `* ${block.text}\n`;
        break;
      case 'ordered-list-item':
        markdown += `1. ${block.text}\n`;
        break;
      default:
        // Process inline styles
        let text = block.text;
        let inlineMarkdown = text;

        // Sort styles by offset in reverse order to process from end to start
        const styles = [];
        block.inlineStyleRanges.forEach(range => {
          styles.push(range);
        });
        styles.sort((a, b) => b.offset - a.offset);

        // Apply inline styles
        styles.forEach(style => {
          const before = inlineMarkdown.slice(0, style.offset);
          const styled = inlineMarkdown.slice(style.offset, style.offset + style.length);
          const after = inlineMarkdown.slice(style.offset + style.length);

          switch (style.style) {
            case 'BOLD':
              inlineMarkdown = `${before}**${styled}**${after}`;
              break;
            case 'ITALIC':
              inlineMarkdown = `${before}_${styled}_${after}`;
              break;
            default:
              break;
          }
        });

        markdown += `${inlineMarkdown}\n\n`;
    }
  });

  // Handle entity-based formatting (mentions, links)
  Object.keys(raw.entityMap).forEach(key => {
    const entity = raw.entityMap[key];
    if (entity.type === 'MENTION') {
      const mention = entity.data.mention;
      markdown = markdown.replace(mention.text, `@${mention.text}`);
    }
    if (entity.type === 'LINK') {
      const { url } = entity.data;
      markdown = markdown.replace(url, `[${url}](${url})`);
    }
  });

  return markdown.trim();
};