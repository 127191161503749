import React, { useState } from "react";
import useAuth from "../../utils/useAuth";
import {
  FaSearch,
  FaChevronDown,
  FaCog,
  FaLock,
  FaGlobe,
  FaUsers,
  FaTimes,
} from "react-icons/fa";
import avtarPic from "../../assets/user.png";
import { useAppDispatch, useAppSelector } from "../../store";
import apiClient from "../../utils/axiosInstance";
import { set } from "lodash";
import { is } from "immutable";
import { toast } from "react-toastify";
import { handleAddShareFolder } from "../../store/DocumentSlice";

const ShareFolderModal = ({ setShareModal, shareModal, folder }) => {
  const dispatch = useAppDispatch();
  const [searchQuery, setSearchQuery] = useState("");
  const [accessLevel, setAccessLevel] = useState("restricted");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showTeamsDropdown, setShowTeamsDropdown] = useState(false);
  const [isTagOpen, setIsTagOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [showMemberDropdown, setShowMemberDropdown] = useState(false);
  const [showTeamList, setShowTeamList] = useState(false);

  const { organizationState, folderState } = useAppSelector((state) => ({
    organizationState: state.organization.organization,
    folderState: state.document.folders,
  }));

  const folderForDisplay = folderState?.folders?.find(
    (fld) => fld.folderId === folder?.folderId
  );

  const { user } = useAuth();
  const teams =
  folderForDisplay?.folder_shared_details?.filter(
      (detail) => detail.accountType === "team"
    ) || [];

  const members =
  folderForDisplay?.folder_shared_details?.filter(
      (detail) => detail.accountType === "member"
    ) || [];

  const org = {
    members: [],
    teams: [],
  }; 
  folderForDisplay?.folder_shared_details?.forEach((item) => {
    if (item.accountType === "team") {
      org.teams.push(item.sharedTo);
    } else if (item.accountType === "member") {
      org.members.push(item.sharedTo);
    }
  });
  org.members.push(user?.userId);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);

    if (event.target.value === "@") {
      setShowMemberDropdown(true);
      setShowTeamList(false);
    } else if (event.target.value === "#") {
      setShowTeamList(true);
      setShowMemberDropdown(false);
    } else {
      setShowMemberDropdown(false);
      setShowTeamList(false);
    }
  };

  const handleMemberSelect = (member) => {
    if (!selectedMembers.find((m) => m.userId === member.userId)) {
      setSelectedMembers([...selectedMembers, member]);
    }
    setSearchQuery("");
    setShowMemberDropdown(false);
  };

  const handleTeamSelect = (team) => {
    if (!selectedTeams.find((t) => t.teamId === team.teamId)) {
      setSelectedTeams([...selectedTeams, team]);
    }
    setSearchQuery("");
    setShowTeamList(false);
  };

  const removeMember = (userId) => {
    setSelectedMembers(
      selectedMembers.filter((member) => member.userId !== userId)
    );
  };

  const removeTeam = (teamId) => {
    setSelectedTeams(selectedTeams.filter((team) => team.teamId !== teamId));
  };

  const handleCloseModel = () => {
    setSearchQuery("");

    setIsTagOpen(false);
    setSelectedMembers([]);
    setSelectedTeams([]);

    setShowTeamList(false);
    setShareModal(false);
  };

  const handleShareFolder = async () => {
    setIsLoading(true);


    const shareData = {
      folderId: folderForDisplay.folderId,
      sharedBy: user.userId,
      sharedWith: [
        ...selectedMembers.map((member) => ({
          sharedTo: member.userId,
          accountType: "member",
        })),
        ...selectedTeams.map((team) => ({
          sharedTo: team.teamId,
          accountType: "team",
        })),
      ],
      createdAt: new Date().toISOString(),
    };

    const shareDataForState = {
      folderId: folderForDisplay.folderId,
      sharedWith: [
        ...selectedMembers.map((member) => ({
          sharedTo: member.userId,
          accountType: "member",
          sharedToname: member.name,
          sharedToemail: member.email
        })),
        ...selectedTeams.map((team) => ({
          sharedTo: team.teamId,
          accountType: "team",
          sharedToname: team.teamName
        })),
      ],
     }
     

    try {
      
      await apiClient().post("/share-folder", shareData);
      dispatch(handleAddShareFolder(shareDataForState));
      setIsLoading(false);
      setSelectedMembers([]);
    setSelectedTeams([]);
      toast.success("Folder shared successfully");
    } catch (error) {
      console.error("Error sharing folder:", error);
      setIsLoading(false);
    }
  };

  

  const renderSelectedItems = () => {
    return (
      <div className="flex flex-wrap gap-2 mb-2">
        {selectedMembers.map((member) => (
          <div
            key={member.userId}
            className="flex items-center bg-purple-100 rounded-full px-3 py-1"
          >
            <span className="text-sm text-purple-600">@{member.name}</span>
            <button
              onClick={() => removeMember(member.userId)}
              className="ml-2 text-gray-500 hover:text-gray-700"
            >
              <FaTimes size={12} />
            </button>
          </div>
        ))}
        {selectedTeams.map((team) => (
          <div
            key={team.teamId}
            className="flex items-center bg-green-100 rounded-full px-3 py-1"
          >
            <span className="text-sm text-green-600">#{team.teamName}</span>
            <button
              onClick={() => removeTeam(team.teamId)}
              className="ml-2 text-gray-500 hover:text-gray-700"
            >
              <FaTimes size={12} />
            </button>
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      {shareModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white rounded-lg w-[500px] p-6 space-y-6 shadow-lg">
            <div className="flex items-center justify-between">
              <h2 className="text-lg font-semibold">
                Share "{folder?.folderName}"
              </h2>
              <div className="flex space-x-2">
                <button className="p-1 rounded-full hover:bg-gray-100 w-8 h-8 flex items-center justify-center">
                  <FaCog size={16} />
                </button>
                <button
                  className="p-1 rounded-full hover:bg-gray-100 w-8 h-8 flex items-center justify-center"
                  onClick={handleCloseModel}
                >
                  <span className="text-2xl">&times;</span>
                </button>
              </div>
            </div>

            <div className="relative">
              <div className="relative">
                <FaSearch
                  className="absolute left-3 top-3 text-gray-400"
                  size={20}
                />
                <input
                  type="text"
                  placeholder="#spaces @members"
                  className="w-full py-3 pl-10 pr-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={searchQuery}
                  onChange={handleSearch}
                />

                {/* Member Dropdown */}
                {showMemberDropdown && organizationState?.[0]?.members && (
                  <div className="absolute w-full mt-1 bg-white border rounded-lg shadow-lg z-20 max-h-60 overflow-y-auto">
                    {organizationState[0].members
                      .filter((member) => !org.members.includes(member.userId) && !selectedMembers.includes(member))
                      .map((member) => (
                        <div
                          key={member.userId}
                          className="px-4 py-2 hover:bg-gray-50 cursor-pointer flex items-center"
                          onClick={() => handleMemberSelect(member)}
                        >
                          <img
                            src={member.userPictureUrl || avtarPic}
                            alt={member.name}
                            className="w-8 h-8 rounded-full mr-2"
                          />
                          <div>
                            <p className="text-sm font-medium">{member.name}</p>
                            <p className="text-xs text-gray-500">
                              {member.email}
                            </p>
                          </div>
                        </div>
                      ))}
                  </div>
                )}

                {/* Team Dropdown */}
                {showTeamList && organizationState?.[0]?.teams && (
                  <div className="absolute w-full mt-1 bg-white border rounded-lg shadow-lg z-20 max-h-60 overflow-y-auto">
                    {organizationState[0].teams
                      .filter((team) => !org.teams.includes(team.teamId) && !selectedTeams.includes(team)) // Exclude teams already in org.teams
                      .map((team) => (
                        <div
                          key={team.teamId}
                          className="px-4 py-2 hover:bg-gray-50 cursor-pointer flex items-center"
                          onClick={() => handleTeamSelect(team)}
                        >
                          <FaUsers className="text-gray-600 mr-2" size={18} />
                          <span className="text-sm">{team.teamName}</span>
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </div>
            {renderSelectedItems()}

            <div className="space-y-4">
              <h3 className="text-sm font-medium text-gray-700">
                General access
              </h3>
              <div className="relative">
                <button
                  className="w-full flex items-center justify-between p-3 border rounded-lg hover:bg-gray-50"
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                >
                  <div className="flex items-center space-x-2">
                    {accessLevel === "restricted" ? (
                      <FaLock className="text-gray-600" size={18} />
                    ) : (
                      <FaGlobe className="text-gray-600" size={18} />
                    )}
                    <div>
                      <p className="text-sm font-medium text-left">
                        {accessLevel === "restricted"
                          ? "Restricted"
                          : "Anyone in this Organization"}
                      </p>
                      <p className="text-xs text-gray-500">
                        {accessLevel === "restricted"
                          ? "Only people with access can open"
                          : "Anyone in the organization can access"}
                      </p>
                    </div>
                  </div>
                  <FaChevronDown
                    size={20}
                    className={`text-gray-400 transition-transform ${
                      isDropdownOpen ? "rotate-180" : ""
                    }`}
                  />
                </button>

                {isDropdownOpen && (
                  <div className="absolute w-full mt-1 bg-white border rounded-lg shadow-lg z-10">
                    <button
                      className="w-full px-4 py-2 text-left hover:bg-gray-50 flex items-center space-x-2"
                      onClick={() => {
                        setAccessLevel("restricted");
                        setIsDropdownOpen(false);
                      }}
                    >
                      <FaLock className="text-gray-600" size={18} />
                      <span>Restricted</span>
                    </button>
                    <button
                      className="w-full px-4 py-2 text-left hover:bg-gray-50 flex items-center space-x-2"
                      onClick={() => {
                        setAccessLevel("anyone");
                        setIsDropdownOpen(false);
                      }}
                    >
                      <FaGlobe className="text-gray-600" size={18} />
                      <span>Anyone in this Organization</span>
                    </button>
                  </div>
                )}
              </div>
            </div>

            <div className="space-y-4">
              <div className="relative">
                <button
                  className="w-full flex items-center justify-between p-3 border rounded-lg hover:bg-gray-50"
                  onClick={() => setShowTeamsDropdown(!showTeamsDropdown)}
                >
                  <div className="flex items-center space-x-2">
                    <FaUsers className="text-gray-600" size={18} />
                    <div>
                      <p className="text-sm font-medium text-left">{`Teams With Access (${teams.length})`}</p>
                      <p className="text-xs text-gray-500 text-left">
                        Shared with teams
                      </p>
                    </div>
                  </div>
                  <FaChevronDown
                    size={20}
                    className={`text-gray-400 transition-transform ${
                      showTeamsDropdown ? "rotate-180" : ""
                    }`}
                  />
                </button>

                {showTeamsDropdown && (
                  <div className="absolute w-full mt-1 bg-white border rounded-lg shadow-lg z-10">
                    {teams.map((team, index) => (
                      <div
                        key={index}
                        className="px-4 py-2 flex items-center space-x-2 hover:bg-gray-50"
                      >
                        <FaUsers className="text-gray-600" size={18} />
                        <span className="text-sm">{team.sharedToname}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>

            <div className="space-y-4">
              <h3 className="text-sm font-medium text-gray-700">
                People with access
              </h3>
              <div
                className="space-y-3 overflow-y-auto"
                style={{ maxHeight: "200px", width: "100%" }}
              >
                <div className="flex items-center space-x-3">
                  <img
                    src={user?.avatar || avtarPic}
                    alt="User avatar"
                    className="w-10 h-10 rounded-full"
                  />
                  <div className="flex-1">
                    <p className="text-sm font-medium">{user?.name} (you)</p>
                    <p className="text-sm text-gray-500">{user?.email}</p>
                  </div>
                  <span className="text-sm text-gray-500">Owner</span>
                </div>

                {members.map((member, index) => (
                  <div key={index} className="flex items-center space-x-3">
                    <img
                      src={avtarPic}
                      alt="Member avatar"
                      className="w-10 h-10 rounded-full"
                    />
                    <div className="flex-1">
                      <p className="text-sm font-medium">
                        {member.sharedToname}
                      </p>
                      <p className="text-sm text-gray-500">
                        {member.sharedToemail}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="flex justify-end pt-4">
              <button
                className="px-6 py-2 rounded-lg text-white"
                style={{
                  backgroundColor: organizationState?.[0]?.secondaryColor,
                }}
                onClick={handleShareFolder}
              >
                {isLoading ? <span className="loader"></span> : "Share Folder"}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ShareFolderModal;
