import { useEffect, useRef, useState } from "react";
import apiClient from "../../utils/axiosInstance";
import { AiOutlineDownload } from "react-icons/ai";
import { useAppDispatch, useAppSelector } from "../../store";
import { Icon } from "@iconify/react/dist/iconify.js";
import chinaUnion from "../../assets/chinaUnion.png";
import americanExp from "../../assets/americanExpress.png";
import discover from "../../assets/discover.png";
import diners from "../../assets/dinerclub.png";
import eftpos from "../../assets/EFTPOS.png";
import jcb from "../../assets/jcb.png";
import mastercard from "../../assets/master-card.png";
import visa from "../../assets/visa.png";
import { toast } from "react-toastify";
import { set } from "lodash";
import { PuffLoader } from "react-spinners";
import { IoMdArrowDropright } from "react-icons/io";
import CustomPricingTable from "./CustomPricingTable";
import { getOrganization } from "../../store/organization";
import { getUserDetails } from "../../store/authSlice";
import Spinner from "react-spinner-material";
import { use } from "react";

const Subscription = ({ userDetailsState, org, user }) => {
  const dispatch = useAppDispatch();
  const [stripeData, setStripeData] = useState([]);
  const [viewMoreCards, setViewMoreCards] = useState(false);
  const [invoiceHistory, setInvoiceHistory] = useState([]);
  const [counts, setCounts] = useState(0);
  const [offset, setOffset] = useState(0);
  const [loader1, setLoader1] = useState(false);
  const [loader2, setLoader2] = useState(false);

  const [showStripeModal, setShowStripeModal] = useState(false);
  const [isNewInvitation, setIsNewInvitation] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [inviteEmailForInvite, setInviteEmailForInvite] = useState("");

  const [showUnsubscribeModal, setShowUnsubscribeModal] = useState(false);
  const [isManageSubscriptionLoading, setManageSubscriptionIsLoading] =
    useState(false);

  const { organizationState } = useAppSelector((state) => ({
    organizationState: state.organization.organization,
  }));

  const invitedMembers = org?.members
    .filter(
      (member) => member.invitedBy === user?.userId && !member.isSubscribe
    )
    ?.map((member) => member.email);

  const fetchInvoiceHistory = async () => {
    try {
      setLoader2(true);
      const response = await apiClient().post(
        `/stripe/invoice?limit=10&offset=${offset}`
      );
      if (response.status === 200) {
        setOffset(offset + 10);
        setInvoiceHistory([...invoiceHistory, ...response.data.invoice_list]);
        setCounts(response.data.invoice_list.length);
      }
    } catch (error) {
      console.log("error getting card details", error);
      toast.error(`${error}`);
    }
    setLoader2(false);
  };

  useEffect(() => {
    fetchInvoiceHistory();
  }, []);

  const convertToIST = (timestamp) => {
    const date = new Date(timestamp * 1000);

    return date.toLocaleDateString("en-IN", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      timeZone: "Asia/Kolkata",
    });
  };

  const confirmUnsubscribe = async () => {
    try {
      setLoader1(true);
      await apiClient().post(`stripe/subscriptions/cancel`);
      dispatch(getOrganization());
      dispatch(getUserDetails(user?.userId));
      toast.success("Unsubscribed successfully");
    } catch (error) {
      console.log("Error while unsubscirbing organization", error);
    } finally {
      setShowUnsubscribeModal(false);
      setLoader1(false);
    }
  };

  const subscriptionDate =
    userDetailsState?.subscriptionDate || new Date(org?.createdAt);

  // Function to add months to a date
  function addMonths(date, months) {
    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() + months);
    return newDate;
  }

  // Function to calculate remaining days
  function calculateRemainingDays(targetDate) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const diffTime = targetDate - today;
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }

  const trialEndDate = new Date(org.createdAt);
  const trialFutureDate = trialEndDate.setDate(trialEndDate.getDate() + 7);
  const remainingDaysTrial = Math.max(calculateRemainingDays(trialEndDate), 0);

  const monthlyFutureDate = addMonths(subscriptionDate, 1);

  const annualFutureDate = addMonths(subscriptionDate, 12);

  let remainingDays = 0;
  let renewalDate;

  if (org?.FreeTrail === true) {
    remainingDays = trialFutureDate;
  }

  if (userDetailsState?.isSubscribe) {
    if (userDetailsState?.planInterval === "month") {
      renewalDate = monthlyFutureDate;
    } else if (userDetailsState?.planInterval === "year") {
      renewalDate = annualFutureDate;
    }
  }

  const handleManageSubscribe = async () => {
    try {
      setManageSubscriptionIsLoading(true);
      const response = await apiClient().get("/stripe/subscriptions/manage");
      if (response.status === 200) {
        window.location.href = response.data.url;
        setManageSubscriptionIsLoading(false);
      }
    } catch (error) {
      console.log("Error while unsubscirbing organization", error);
    }
  };

  return (
    <>
      <div className="max-w-2xl py-6 space-y-6">
        <div className="border border-gray-200 rounded-lg p-4 bg-gray-50">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-[12px] text-purple-600 font-semibold">
                CURRENT PLAN
              </p>
              <div className="text-lg font-medium text-gray-800">
                {org.FreeTrial ? (
                  <p className="text-2xl font-bold text-[#2A2A2A] mt-4">
                    Free trial!
                  </p>
                ) : userDetailsState?.isSubscribe ? (
                  <p className="text-2xl font-bold text-[#2A2A2A] mt-4">
                    {userDetailsState?.planInterval === "month"
                      ? "Monthly Plan"
                      : "Yearly Plan"}
                  </p>
                ) : (
                  <p className="text-2xl font-bold text-[#2A2A2A] mt-4">
                    No Active Plan
                  </p>
                )}
              </div>
            </div>

            <div>
              {org.FreeTrial ? (
                <>
                  {remainingDaysTrial > 1 ? (
                    <span className="text-lg mt-2">
                      {remainingDaysTrial} days left in your free trial
                    </span>
                  ) : remainingDaysTrial === 1 ? (
                    <span className="text-lg mt-2">
                      1 day left in your free trial
                    </span>
                  ) : remainingDaysTrial === 0 ? (
                    <span className="text-lg mt-2">Today is the last day!</span>
                  ) : null}
                </>
              ) : userDetailsState?.isSubscribe ? (
                <>
                  <p className="text-sm text-[#7E7E7E]">
                    {userDetailsState?.hasOwnProperty("cancel_at") &&
                    userDetailsState?.cancel_at !== null ? (
                      <>
                        Your subscription will cancel on{" "}
                        <span className="font-medium">
                          {convertToIST(userDetailsState?.cancel_at)}
                        </span>
                      </>
                    ) : (
                      renewalDate && (
                        <>
                          Your subscription will renew on{" "}
                          <span className="font-medium">
                            {renewalDate?.toLocaleDateString("en-US", {
                              month: "short",
                              day: "numeric",
                              year: "numeric",
                            })}
                          </span>
                        </>
                      )
                    )}
                  </p>
                </>
              ) : (
                <span className="text-lg mt-2">
                  You are not subscribed to any plan.
                </span>
              )}
              <div className="flex items-center justify-end">
                {!userDetailsState?.isSubscribe ? (
                  <button
                    onClick={() => {
                      setShowStripeModal(true);
                    }}
                    className="text-white bg-red-600  font-medium px-4 py-2 mt-2 rounded-full flex items-center text-base hover:scale-105 active:scale-95 transition-transform"
                    // style={{
                    //     background: "linear-gradient(to right, #8AD4EC, #EF96FF, #FF56A9, #FFAA6C)",
                    // }}
                  >
                    Subscribe Now <IoMdArrowDropright className="text-xl" />
                  </button>
                ) : (
                  <>
                    {/* <button
                                        onClick={() => { setShowUnsubscribeModal(true) }} // Assuming you want to show a modal when unsubscribing
                                        className="text-white bg-red-600 font-medium px-4 py-2 mt-2 rounded-full flex items-center text-base hover:scale-105 active:scale-95 transition-transform"
                                    >
                                        Unsubscribe Now <IoMdArrowDropright className="text-xl" />
                                    </button> */}
                    <button
                      onClick={handleManageSubscribe}
                      className={`$ text-black bg-white border border-black font-medium px-4 py-2 mt-2 rounded-full flex items-center justify-center text-base hover:scale-105 active:scale-95 transition-transform min-w-[160px]`}
                    >
                      {isManageSubscriptionLoading ? (
                        <div className="w-6 h-6 border-2 border-black border-t-white rounded-full animate-spin"></div>
                      ) : (
                        <span>Manage Subscribe</span>
                      )}
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* <div>
                    <h2 className="text-2xl font-semibold text-gray-800">Our plans</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                        <div className="border border-gray-200 rounded-lg p-4 hover:shadow-md transition">
                            <h3 className="text-2xl font-medium text-[#2A2A2A]">Monthly</h3>
                            <p className="text-sm text-[#7E7E7E] mt-2">
                                Stay flexible with our monthly subscription, offering easy access without a long-term commitment.
                            </p>
                            <p className="text-[38px] font-semibold text-[#2A2A2A] mt-4">
                                $12 <span className="text-sm font-normal text-[#7E7E7E]">/ per month</span>
                            </p>
                        </div>

                        <div className="border border-gray-200 rounded-lg p-4 hover:shadow-md transition">
                            <h3 className="text-2xl font-medium text-[#2A2A2A]">Annual</h3>
                            <p className="text-sm text-[#7E7E7E] mt-2 min-h-[60px]">
                                Unlock a full year of unlimited access at a discounted rate with our annual subscription.
                            </p>
                            <p className="text-[38px] font-semibold text-[#2A2A2A] mt-4">
                                $120 <span className="text-sm font-normal text-[#7E7E7E]">/ per year</span>
                            </p>
                        </div>
                    </div>
                </div> */}
      </div>

      <div className="account-table w-full overflow-y-auto">
        {/* <div className="w-full">
                    {userDetailsState?.role === "super admin" && (
                        <div className="text-center mx-auto w-full  my-8">
                            {!org.FreeTrial ? <>
                                {!userDetailsState?.isSubscribe ? (<>
                                    <p className="text-lg font-bold text-[#2A2A2A] mb-2">
                                        Subscribe today for unlimited access to all premium features
                                    </p>
                                </>) : (<>
                                    <p className="text-lg font-bold text-[#2A2A2A] mb-2">
                                        End your subscription today and return to the free version of the platform.
                                    </p>
                                </>)}

                                <div className="flex items-center justify-center">
                                    {!userDetailsState?.isSubscribe ? (
                                        <button
                                            onClick={() => { setShowStripeModal(true) }} // Shows Stripe modal for subscribing
                                            className="text-white bg-red-600  font-medium px-4 py-2 rounded-full flex items-center text-base hover:scale-105 active:scale-95 transition-transform"
                                        // style={{
                                        //     background: "linear-gradient(to right, #8AD4EC, #EF96FF, #FF56A9, #FFAA6C)",
                                        // }}
                                        >
                                            Subscribe Now <IoMdArrowDropright className="text-xl" />
                                        </button>) : (<button
                                            onClick={() => { setShowUnsubscribeModal(true) }} // Assuming you want to show a modal when unsubscribing
                                            className="text-white bg-red-600 font-medium px-4 py-2 rounded-full flex items-center text-base hover:scale-105 active:scale-95 transition-transform"
                                        >
                                            Unsubscribe Now <IoMdArrowDropright className="text-xl" />
                                        </button>
                                    )}
                                </div>
                            </> : <p className="text-lg font-bold text-[#2A2A2A] mb-2">
                                Current plan is free trial!
                            </p>}
                        </div>
                    )}
                </div> */}

        {showStripeModal && (
          <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <div className="bg-white rounded-lg overflow-hidden shadow-xl max-w-md w-full">
              <div className="p-4 bg-gray-100 flex justify-between items-start">
                <h2 className="text-lg ">Subscribe</h2>
                <button
                  onClick={() => {
                    setShowStripeModal(false);
                    setSelectedMember(null);
                  }}
                  className="text-gray-500 hover:text-gray-700"
                >
                  <Icon icon="heroicons:x-mark" width="24" height="24" />
                </button>
              </div>
              <CustomPricingTable
                setShowStripeModal={setShowStripeModal}
                invitedMembers={
                  isNewInvitation ? [inviteEmailForInvite] : invitedMembers
                }
                isNewInvitation={isNewInvitation}
                userDetails={userDetailsState}
              />
            </div>
          </div>
        )}

        {showUnsubscribeModal && (
          <UnsubscribeModel
            setShowModal={setShowUnsubscribeModal}
            confirmUnsubscribe={confirmUnsubscribe}
            loader1={loader1}
          />
        )}

        {userDetailsState?.isSubscribe && (
          <div className=" w-full ">
            <h2 className="text-lg font-bold mb-4">Invoice History</h2>
            <div className=" overflow-y-auto">
              <table className="min-w-full  mb-2 ">
                <thead className="sticky top-0 bg-white">
                  <tr className="flex items-center justify-between">
                    <th className="px-4 py-2 text-left text-sm font-medium text-gray-600 w-[25%]">
                      Date
                    </th>
                    <th className="px-4 py-2 text-center text-sm font-medium text-gray-600 w-[25%]">
                      Amount
                    </th>
                    <th className="px-4 py-2 text-center text-sm font-medium text-gray-600 w-[25%]">
                      Status
                    </th>
                    <th className="px-4 py-2 text-center text-sm font-medium text-gray-600 w-[25%]">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loader2 ? (
                    <>
                      <tr className="border-t h-[50px] flex items-center justify-between">
                        <td className="px-4 py-2 text-left text-sm w-[25%]">
                          <div className="bg-gray-200 rounded animate-pulse mr-2 w-[80%] h-[18px]"></div>
                        </td>
                        <td className="px-4 py-2 text-center text-sm w-[25%] flex justify-center">
                          <div className="bg-gray-200 rounded animate-pulse mr-2 w-[80%] h-[18px]"></div>
                        </td>
                        <td className="px-4 py-2 text-center text-sm w-[25%] flex justify-center">
                          <div className="bg-gray-200 rounded animate-pulse mr-2 w-[80%] h-[18px]"></div>
                        </td>
                        <td className="px-4 py-2 flex items-center justify-center text-xl w-[25%]">
                          <div className="bg-gray-200 rounded animate-pulse mr-2 w-[30%] h-[18px]"></div>
                        </td>
                      </tr>
                    </>
                  ) : invoiceHistory.length === 0 ? (
                    <tr>
                      <td colSpan="4" className="text-center py-4">
                        No Invoice History
                      </td>
                    </tr>
                  ) : (
                    <RenderInvocieHistory invoiceHistory={invoiceHistory} />
                  )}
                </tbody>
              </table>
            </div>
            {counts >= 10 && (
              <div
                className="mt-4 flex text-sm cursor-pointer text-gray-500 hover:underline hover:text-gray-700 items-center justify-center"
                onClick={() => {
                  if (!loader2) {
                    fetchInvoiceHistory();
                  }
                }}
              >
                {loader2 ? (
                  <div className="spinner flex items-center justify-center">
                    <PuffLoader
                      color={
                        userDetailsState?.primaryColor ||
                        organizationState[0]?.primaryColor
                      }
                      cssOverride={null}
                      loading
                      size={40}
                    />
                  </div>
                ) : (
                  "Load More"
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

const renderCard = (card) => {
  const cardLogos = [
    {
      brand: "American Express",
      logo: americanExp,
    },
    {
      brand: "China UnionPay",
      logo: chinaUnion,
    },
    {
      brand: "Discover",
      logo: discover,
    },
    {
      brand: "Diners Club",
      logo: diners,
    },
    {
      brand: "eftpos Australia",
      logo: eftpos,
    },
    {
      brand: "Japan Credit Bureau",
      logo: jcb,
    },
    {
      brand: "Mastercard",
      logo: mastercard,
    },
    {
      brand: "Visa",
      logo: visa,
    },
  ];

  const logo = cardLogos.find(
    (logo) => logo.brand.toLowerCase() === card.brand.toLowerCase()
  )?.logo;

  return (
    <tr
      key={card.id}
      className="border-t h-[50px] flex items-center justify-between"
    >
      <td className="px-4 py-2 text-center flex items-center w-[33%]">
        {logo && (
          <img
            src={logo}
            alt={card.brand}
            className="w-16 h-[40px] object-contain mr-2"
          />
        )}
      </td>
      <td className="px-4 py-2 text-center text-sm w-[33%]">
        •••• {card.last4}
      </td>
      <td className="px-4 py-2 text-center text-sm w-[33%]">
        {`${card.exp_month}/${card.exp_year.toString().slice(-2)}`}
      </td>
    </tr>
  );
};

const RenderInvocieHistory = ({ invoiceHistory }) => {
  const convertTimeStamp = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleString();
  };
  return (
    <>
      {invoiceHistory.map((invoice) => (
        <tr
          key={invoice.invoice_id}
          className="border-t h-[50px] flex items-center justify-between"
        >
          <td className="px-4 py-2 text-left text-sm w-[25%]">
            {convertTimeStamp(invoice.created)}
          </td>
          <td className="px-4 py-2 text-center text-sm w-[25%]">
            ${(invoice.amount / 100).toFixed(2)}
          </td>
          <td className="px-4 py-2 text-center text-sm w-[25%]">
            {invoice.status}
          </td>
          <td className="px-4 py-2 flex items-center justify-center text-xl w-[25%] ">
            <a
              href={invoice?.invoice_link}
              target="_blank"
              className="hover:text-blue-900"
            >
              <AiOutlineDownload />
            </a>
          </td>
        </tr>
      ))}
    </>
  );
};

export default Subscription;

const UnsubscribeModel = ({ setShowModal, confirmUnsubscribe, loader1 }) => {
  const { organizationState } = useAppSelector((state) => ({
    organizationState: state.organization.organization,
  }));

  return (
    <>
      <div className="fixed pl-64 top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 z-50">
        <div
          className="py-4 px-8 rounded-lg w-[350px] md:w-[600px] bg-white z-51 "
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <div className="flex items-center justify-between my-3">
            <h2 className="text-2xl font-bold">Unsubscribe</h2>
            <button
              onClick={() => setShowModal(false)}
              className="text-gray-500 hover:text-gray-700"
            >
              <Icon icon="ic:baseline-close" width="1.2em" height="1.2em" />
            </button>
          </div>
          <div className="flex flex-col items-center justify-center my-5">
            <p className="text-lg text-center font-normal w-full mb-3 text">
              You about to Unsubscribe from premium features and member
              privileges.!
            </p>
          </div>
          <div className="flex items-center justify-center my-3">
            <button
              onClick={() => setShowModal(false)}
              className="text-md font-normal py-2 px-5 border-2 rounded-md  transition-colors duration-300 mx-4"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                if (loader1) return;
                confirmUnsubscribe();
              }}
              className="text-md text-center font-normal  text-white py-2 px-5 border-2 rounded-md transition-colors duration-300 mx-4 w-[198px] h-[43px]"
              style={{ backgroundColor: organizationState[0]?.primaryColor }}
              disabled={loader1}
            >
              {loader1 ? (
                <Spinner
                  radius={16}
                  stroke={2}
                  size={100}
                  color="white"
                  style={{ margin: "auto" }}
                />
              ) : (
                "Confirm Unsubscribe"
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
