import { Icon } from "@iconify/react";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../store/index";
import apiClient from "../utils/axiosInstance";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import { Link, useNavigate, useLocation } from "react-router-dom";
import useAuth from "../utils/useAuth";
import TeamModal from "./Team/TeamModal";
import {
  getTeams,
  updateTeamNotification,
  updateTeamNotificatioOnOrOff,
} from "../store/teamSlice";
import WebChatModel from "./WebChatModel";
import {
  deleteUserLimitedChat,
  getUserChatsWithinLimit,
  resetMainThreadNotification,
  starredThread,
  updateChatNotification,
  getStarredThreads,
  getDirectMessages,
  resetDMNotification,
  addNewDM,
  changeMainThreadNotification,
  updateThreadNotificatioOnOrOff,
} from "../store/finChat";
import { setActiveTab, setExpandedTab } from "../store/sidebarSlice";
import {
  getOrganization,
  resetMemberNotification,
} from "../store/organization";
import { format } from "date-fns";
import Modal from "./Modal";
import SidebarRenderThreads from "./sidebar/SidebarRenderThreads";
import SidebarRenderDMs from "./sidebar/SidebarRenderDMs";
import SidebarRenderFolders from "./sidebar/SidebarRenderFolders";
import SidebarRenderSpace from "./sidebar/SidebarRenderSpace";
import SidebarRenderStarred from "./sidebar/SidebarRenderStarred";
import { getUserDetails } from "../store/authSlice";
import { useSelector } from "react-redux";
import { IoIosAddCircle } from "react-icons/io";
import { RiDeleteBin6Line } from "react-icons/ri";
import { RxCross2 } from "react-icons/rx";
import { IoAdd } from "react-icons/io5";
import chatIcon from "../assets/chat_icon.png";
import { setFetchDms, thread } from "../store/ThreadSlice";
import userPicture from "../assets/user.png";

function Sidebar({
  isOpenWebModal,
  setIsOpenWebModal,
  SetDashboard,
  dashboard,
}) {
  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const paramId = queryParams.get("id");

  const {
    chatWithLimitState,
    teamState,
    expandedTab,
    dashboardActiveTabState,
    userDetailsState,
    starredThreadState,
    starredStatus,
    organizationState,
    DirectMessages,
    fetchDmsStatus,
  } = useAppSelector((state) => ({
    userDetailsState: state.auth.userDetails,
    chatWithLimitState: state.finChat.chatWithLimit,
    teamState: state.teams.teams,
    organizationState: state.organization.organization,
    expandedTab: state.sidebar.expandedTab,
    dashboardActiveTabState: state.sidebar.dashboardActiveTab,
    starredThreadState: state.finChat.starredThreads,
    starredStatus: state.finChat.starredStatus,
    DirectMessages: state.finChat.DirectMessages,
    fetchDmsStatus: state.finChat.fetchDmsStatus,
  }));
  const [teamModal, setTeamModal] = useState(false);
  const [initialTeam, setInitialTeam] = useState(null);
  const [loadingTab, setLoadingTab] = useState([
    "starred",
    "threads",
    "spaces",
    "dms",
  ]);
  const [activeItemId, setActiveItemId] = useState(null);
  const [showButtons, setShowButtons] = useState(false);
  const [teamShowButtons, setTeamShowButtons] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [discardedThreadId, setDiscardedThreadId] = useState(null);
  const [showAllStarred, setShowAllStarred] = useState(false);
  const [showAllThread, setShowAllThread] = useState(false);
  const [showAllSpaces, setShowAllSpaces] = useState(false);
  const [showAllDms, setShowAllDms] = useState(false);
  const [newDMList, setNewDMList] = useState([]);
  const [startNewChat, setStartNewChat] = useState(false);
  const [threadNotification, setThreadNotification] = useState(0);
  const [starredNotification, setStarredNotification] = useState(0);
  const [isNotificationOn, setIsNotificationOn] = useState(false);
  const [isThreadDiscardLoading, setIsThreadDiscardLoading] = useState(false);

  const notificationofstarred = chatWithLimitState?.history?.[0]?.starred
    ? chatWithLimitState.notification
    : "";

  useEffect(() => {
    if (user && !userDetailsState) {
      dispatch(getUserDetails(user?.userId));
    }
  }, [user]);

  useEffect(() => {
    let threadNoti = 0;
    let starredNoti = 0;
    chatWithLimitState?.history?.forEach((item) => {
      if (!item.starred) {
        threadNoti += item.notification;
      } else {
        starredNoti += item.notification;
      }
    });
    setThreadNotification(threadNoti);
    setStarredNotification(starredNoti);
  }, [chatWithLimitState]);

  useEffect(() => {
    dispatch(getDirectMessages());
  }, []);

  useEffect(() => {
    if (DirectMessages && Array.isArray(DirectMessages)) {
      const newDms = [];
      organizationState[0]?.members?.filter((member) => {
        if (member.isAccepted && member.userId !== user?.userId) {
          const dm = DirectMessages.find((dm) => dm.userId === member.userId);
          if (!dm) {
            const obj = {
              id: member.userId,
              userId: member.userId,
              name: member.name,
              notification: member.notification,
              userStatus: member.userStatus,
              userPicture: member.userPicture,
            };
            newDms.push(obj);
          }
        }
      });
      setNewDMList(newDms);
    }
  }, [DirectMessages]);

  useEffect(() => {
    if (activeItemId !== null) {
      setCloseSideBar(true);
    }
  }, [activeItemId]);

  useEffect(() => {
    const pathParts = location.pathname.split("/");
    const lastPart = pathParts[pathParts.length - 1];
    if (
      lastPart !== "dashboard" &&
      lastPart !== "chat-history" &&
      lastPart !== "teams" &&
      lastPart !== "dms" &&
      lastPart !== "folders" &&
      lastPart !== "starred"
    ) {
      setActiveItemId(lastPart);
    } else {
      setActiveItemId(null);
    }
  }, [location]);

  useEffect(() => {
    if (user?.userId && !chatWithLimitState?.history?.length > 0) {
      dispatch(getUserChatsWithinLimit(15));
    }
  }, [user]);

  useEffect(() => {
    if (!starredThreadState?.threads?.length > 0) {
      dispatch(getStarredThreads());
    }
  }, []);

  useEffect(() => {
    if (
      location?.state?.isFirstTime &&
      starredThreadState?.threads?.length > 0
    ) {
      const starredThread = starredThreadState?.threads[0];
      setActiveItemId(starredThread.threadId);
      if (starredThread.type === "chat") {
        navigate(`/answer-search?id=${starredThread.threadId}`);
      } else {
        navigate(`/thread?id=${starredThread.threadId}`);
      }
    }
  }, [location?.state, starredThreadState]);

  const handleItemClick = (itemId) => {
    setActiveItemId(itemId);
  };

  const discardedThread = async () => {
  
    try {
      await apiClient().put(`/thread-starred-discarded/${discardedThreadId}`, {
        field: "discarded",
      });
      dispatch(deleteUserLimitedChat(discardedThreadId));
      setIsThreadDiscardLoading(false);
    setShowModal(false);

    } catch (error) {
      console.error("Error discarding thread:", error);
    }
  };

  const openDashboard = () => {
    navigate("/dashboard");
    if (typeof SetDashboard === "function") {
      SetDashboard(true);
    } else {
      console.error("SetDashboard is not a function");
    }
  };

  const handleChangeNotification = async (item) => {
    // console.log("🚀 ~ handleChangeNotification ~ item:", item);
    if (item.type === "thread") {
      try {
        const response = await apiClient().put(
          `/thread-starred-discarded/${item.id}`,
          {
            field: item.notificationOn ? "notificationOff" : "notificationOn",
          }
        );
        console.log(
          "response notificationOn:",
          response.data.thread.notificationOn
        );
        const payload = {
          threadId: item.id,
          notificationOn: response.data.thread.notificationOn,
        };

        dispatch(updateThreadNotificatioOnOrOff(payload));
      } catch (error) {
        console.error("Error discarding thread:", error);
      }
    } else {
      try {
        const member = item.members.find((member) => member.email === user?.email);
        const response = await apiClient().post(
          "/team-notification-status-update",
          {
            teamId: item.id,
            notificationStatus: member.notificationOn ? false : true,
          }
        );
        console.log(
          "response notificationOn:",
          response.data.team.notificationOn
        );
        const payload = {
          teamId: item.id,
          memberEmail:member.email,
          notificationOn: response.data.team.notificationOn,
        };

        dispatch(updateTeamNotificatioOnOrOff(payload));
      } catch (error) {
        console.error("Error discarding thread:", error);
      }
    }
  };

  const handleDiscarded = (threadId) => {
  
    setShowModal(true);
    setDiscardedThreadId(threadId);
  };

  const confirmDiscardedThread = () => {
    setIsThreadDiscardLoading(true);
    discardedThread();
    setShowButtons(false);
  };

  const isItemActive = (itemId) => {
    return activeItemId === itemId || paramId === itemId;
  };

  const openModal = (tabType) => {
    if (tabType === "spaces") {
      setTeamModal(true);
    }
  };

  useEffect(() => {
    setLoadingTab(["starred", "threads", "spaces", "dms"]);
    Promise.all([
      dispatch(getStarredThreads()),
      dispatch(getUserChatsWithinLimit(15)),
      dispatch(getOrganization()),
      dispatch(getTeams()),
    ]).finally(() => setLoadingTab([]));
  }, []);

  const handleTabClick = async (tab) => {
    if (!expandedTab.includes(tab)) {
      setLoadingTab([tab]);
      switch (tab) {
        case "starred":
          if (starredThreadState?.threads?.length === 0) {
            dispatch(getStarredThreads()).finally(() => setLoadingTab([]));
          } else {
            setLoadingTab([]);
            if (starredThreadState?.notification > 0) {
              await apiClient().put(`/reset-notification/${user?.userId}`);
              dispatch(resetMainThreadNotification());
            }
          }
          break;
        case "threads":
          if (chatWithLimitState?.length === 0) {
            dispatch(getUserChatsWithinLimit(15)).finally(() =>
              setLoadingTab([])
            );
          } else {
            setLoadingTab([]);
            if (chatWithLimitState?.notification > 0) {
              await apiClient().put(`/reset-notification/${user?.userId}`);
              dispatch(resetMainThreadNotification());
            }
          }
          break;
        case "dms":
          if (organizationState?.length === 0) {
            dispatch(getOrganization()).finally(() => setLoadingTab([]));
          } else {
            setLoadingTab([]);
          }
          break;
        case "spaces":
          if (!teamState?.length > 0) {
            dispatch(getTeams()).finally(() => setLoadingTab([]));
          } else {
            setLoadingTab([]);
          }
          break;
      }
    } else {
      setLoadingTab([]);
    }
    dispatch(setExpandedTab(tab));
  };

  const resetNotification = async (teamId) => {
    try {
      await apiClient().put(`/reset-notification/${teamId}`);
      dispatch(updateTeamNotification({ teamId: teamId, notification: 0 }));
    } catch (error) {
      console.log("error in reset notification:", error);
    }
  };

  const resetThreadChatsNotification = async (threadId) => {
    try {
      await apiClient().put(`/reset-notification/${threadId}`);
      dispatch(updateChatNotification({ threadId: threadId, notification: 0 }));
    } catch (error) {
      console.error("Error resetting notification:", error);
    }
  };

  const resetOrgMemberNotification = async (memberId) => {
    try {
      await apiClient().put(`/reset-notification/${memberId}`);
      dispatch(
        resetMemberNotification({ memberId: memberId, notification: 0 })
      );
      dispatch(resetDMNotification({ memberId: memberId, notification: 0 }));
    } catch (error) {
      console.error("Error resetting notification:", error);
    }
  };

  const handleStarredThread = async (threadId) => {
    try {
      dispatch(starredThread(threadId));
      await apiClient().put(`/thread-starred-discarded/${threadId}`, {
        field: "starred",
      });
    } catch (error) {
      console.error("Error starring thread:", error);
    }
  };

  const TabContent = ({
    items,
    linkPrefix1,
    tabType,
    openModal,
    isLoading,
    closeSideBar,
    setCloseSideBar,
  }) => {
    const starredItems = starredThreadState?.threads
      ?.filter((item) => item.starred)
      .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    const moreStaaredDislay = showAllStarred
      ? starredItems
      : starredItems?.slice(0, 10);
    const moreThreadDislay = showAllThread ? items : items?.slice(0, 10);
    const moreSpacesDislay = showAllSpaces ? items : items?.slice(0, 10);
    const moreDmsDislay = showAllDms ? items : items?.slice(0, 10);
    const [showMore, setShowMore] = useState(false);
    const [showButtons, setShowButtons] = useState(null);

    const threadItems = items
      ?.filter((item) => !item.starred)
      .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    const displayedItems = showMore ? threadItems : threadItems?.slice(0, 10);

    const starredThreadItems = items
      ?.filter((item) => item.starred)
      .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    const displayedStarredItems = showAllStarred
      ? starredThreadItems
      : starredThreadItems?.slice(0, 10);

    return isLoading ? (
      <div className="space-y-2 px-2 mt-2">
        {[1, 2, 3, 4].map((_, index) => (
          <div key={index} className="animate-pulse flex items-center">
            <div className="h-4 bg-gray-200 rounded w-full"></div>
          </div>
        ))}
      </div>
    ) : tabType === "starred" ? (
      <>
        <ul className="space-y-2 max-h-[30rem] hover:overflow-y-auto  overflow-hidden">
          {starredThreadItems.length === 0 ? (
            <div className="flex items-center gap-2 p-2 rounded">
              <p className="text-sm text-pricingText text-center w-full   ">
                No Starred Thread
              </p>
            </div>
          ) : (
            <>
              {displayedStarredItems?.map((item, index) => {
                return (
                  <li
                    key={index}
                    onClick={() => setCloseSideBar(true)}
                    className="text-sm flex items-center rounded-lg p-1 transition-colors duration-200 hover:bg-slate-200"
                    style={{
                      background: isItemActive(item.id)
                        ? organizationState[0]?.secondaryColor
                        : "",
                    }}
                  >
                    <div
                      className="relative group w-full"
                      onMouseEnter={() => setShowButtons(index)}
                      onMouseLeave={() => setShowButtons(false)}
                    >
                      <Link
                        to={
                          item.type === "chat"
                            ? `${linkPrefix1}?id=${item.id}&tabType=dashboard`
                            : `/thread?id=${item?.id}&tabType=dashboard`
                        }
                        style={{
                          color: isItemActive(item.id) ? "white" : "black",
                        }}
                        className={`truncate w-full flex items-center text-landingText capitalize font-normal ${
                          item.notification > 0
                            ? "text-gray-900 font-semibold"
                            : ""
                        }`}
                        onClick={(e) => {
                          handleItemClick(item.id);
                          if (item.notification > 0) {
                            e.preventDefault();
                            resetThreadChatsNotification(item.id).then(() => {
                              navigate(
                                item.type === "chat"
                                  ? `${linkPrefix1}?id=${item.id}&tabType=dashboard`
                                  : `/thread?id=${item?.id}&tabType=dashboard`
                              );
                            });
                          }
                        }}
                        title={item.name}
                      >
                        {item.type === "thread" ? (
                          <Icon
                            icon="heroicons:chat-bubble-left-ellipsis"
                            width="18px"
                            height="18px"
                            className="text-pricingText mr-2"
                            style={{
                              color: isItemActive(item.id) ? "white" : "black",
                            }}
                          />
                        ) : (
                          <div className="w-[18px] h-[18px] relative flex items-center justify-center mr-2">
                            <img
                              src={chatIcon}
                              alt="chat"
                              width="24px"
                              height="24px"
                              className={`min-w-[24px] min-h-[24px] ${
                                isItemActive(item.id)
                                  ? "brightness-0 invert"
                                  : ""
                              }`}
                            />
                          </div>
                        )}
                        <div className="max-w-[75%] truncate">{item.name}</div>
                        {item.notification > 0 && (
                          <div className="bg-red-500 text-white text-xs font-bold w-5 h-5 p-[1.5px] rounded-full ml-2 text-center flex items-center justify-center">
                            {item.notification > 4 ? "4+" : item.notification}
                          </div>
                        )}
                      </Link>
                      {showButtons === index && (
                        <div className="absolute right-0 top-1/2 -translate-y-1/2 flex space-x-2 rounded-md px-1 py-1 transition-opacity bg-white duration-200 transform scale-90">
                          {/* <p className="text-sm font-normal">
                            {format(new Date(item.createdAt), "d MMM")}
                          </p> */}
                          <button
                            className="rounded"
                            style={{
                              color: organizationState[0]?.secondaryColor,
                            }}
                            onClick={() => handleStarredThread(item?.id)}
                          >
                            {item.starred ? (
                              <Icon
                                icon="mdi:star"
                                width="1.5em"
                                height="1.5em"
                              />
                            ) : (
                              <Icon
                                icon="mdi:star-outline"
                                width="1.5em"
                                height="1.5em"
                              />
                            )}
                          </button>

                        

                          <button
                            className="rounded "
                            style={{
                              color: organizationState[0]?.secondaryColor,
                            }}
                            onClick={() => handleChangeNotification(item)}
                          >
                            {item.notificationOn ? (
                              <Icon
                                icon="basil:notification-on-outline"
                                width="1.2em"
                                height="1.2em"
                              />
                            ) : (
                              <Icon
                                icon="iconamoon:notification-off"
                                width="1.2em"
                                height="1.2em"
                              />
                            )}
                          </button>

                          
                        </div>
                      )}
                    </div>
                  </li>
                );
              })}
            </>
          )}
        </ul>

        {starredThreadItems.length > 10 && (
          <>
            <div className="showmorebtn flex items-center p-1 rounded-lg transition-colors duration-200">
              <div className="icon mr-2">
                {showAllStarred ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </div>
              <div>
                <button
                  onClick={() => setShowAllStarred((prevState) => !prevState)}
                  className="text-slate-500 focus:outline-none focus:ring-none rounded text-sm"
                >
                  {showAllStarred ? "Show Less" : "More"}
                </button>
              </div>
            </div>
          </>
        )}
      </>
    ) : tabType === "threads" ? (
      <>
        {
          // displayedItems?.length === 0 ? (
          //   <div className="flex items-center gap-2 py-2 rounded">
          //     <div className="flex items-center justify-center p-2 rounded-md" style={{backgroundColor:"#e5e7eb"}}><IoAdd/></div>
          //     <button className="text-center text-sm" onClick={() => { navigate(`/dashboard`) }}>
          //       Create new Thread
          //     </button>
          //   </div>
          // ) :
          <>
            <ul className="space-y-2 max-h-[30rem]  hover:overflow-y-auto overflow-hidden">
              {displayedItems?.map((item, index) => {
                return (
                  <li
                    key={index}
                    onClick={() => {
                      setCloseSideBar(true);
                    }}
                    className={`text-sm flex items-center p-1 rounded-lg transition-colors duration-200 hover:bg-slate-200`}
                    style={{
                      background: isItemActive(item.id)
                        ? organizationState[0]?.secondaryColor
                        : "",
                    }}
                  >
                    <div
                      className="relative group w-full"
                      onMouseEnter={() => setShowButtons(index)}
                      onMouseLeave={() => {
                        if (showButtons === index) {
                          setShowButtons(null);
                        }
                      }}
                    >
                      <Link
                        style={{
                          color: isItemActive(item.id) ? "white" : "black",
                        }}
                        to={
                          item.type === "chat"
                            ? `${linkPrefix1}?id=${item.id}&tabType=dashboard`
                            : `/thread?id=${item?.id}&tabType=dashboard`
                        }
                        className={`truncate w-full flex items-center  capitalize font-normal ${
                          item.notification > 0
                            ? "text-gray-900 font-semibold"
                            : ""
                        }`}
                        onClick={(e) => {
                          handleItemClick(item.id);
                          if (item.notification > 0) {
                            e.preventDefault();
                            resetThreadChatsNotification(item.id).then(() => {
                              navigate(
                                item.type === "chat"
                                  ? `${linkPrefix1}?id=${item.id}&tabType=dashboard`
                                  : `/thread?id=${item?.id}&tabType=dashboard`
                              );
                            });
                          }
                        }}
                        title={item.name}
                      >
                        {item.type === "thread" ? (
                          <Icon
                            icon="heroicons:chat-bubble-left-ellipsis"
                            width="18px"
                            height="18px"
                            className="text-pricingText mr-2"
                            style={{
                              color: isItemActive(item.id) ? "white" : "black",
                            }}
                          />
                        ) : (
                          <div className="w-[18px] h-[18px] relative flex items-center justify-center mr-2">
                            <img
                              src={chatIcon}
                              alt="chat"
                              width="24px"
                              height="24px"
                              className={`min-w-[24px] min-h-[24px] ${
                                isItemActive(item.id)
                                  ? "brightness-0 invert"
                                  : ""
                              }`}
                            />
                          </div>
                        )}
                        <div className="max-w-[78%] truncate">{item.name}</div>
                        {item.notification > 0 && (
                          <div className="bg-red-500 text-white text-xs font-bold w-5 h-5 p-[1.5px] rounded-full ml-2 text-center">
                            {item.notification > 4 ? "4+" : item.notification}
                          </div>
                        )}
                      </Link>
                      {showButtons === index && (
                        <div
                          className={`absolute right-0 top-1/2 -translate-y-1/2 flex space-x-2 rounded-md px-2 py-1 transition-opacity bg-white duration-200 scale-90`}
                        >
                          {/* <p className="text-sm font-normal">
                            {format(new Date(item.createdAt), "d MMM")}
                          </p> */}
                          <button
                            className="rounded"
                            style={{
                              color: organizationState[0]?.secondaryColor,
                            }}
                            onClick={() => handleStarredThread(item.id)}
                          >
                            {item.starred ? (
                              <Icon
                                icon="mdi:star"
                                width="1.5em"
                                height="1.5em"
                              />
                            ) : (
                              <Icon
                                icon="mdi:star-outline"
                                width="1.5em"
                                height="1.5em"
                              />
                            )}
                          </button>

                          <button
                            className="rounded"
                            onClick={() => handleChangeNotification(item)}
                            style={{
                              color: organizationState[0]?.secondaryColor,
                            }}
                          >
                            {item.notificationOn ? (
                              <Icon
                                icon="basil:notification-on-outline"
                                width="1.2em"
                                height="1.2em"
                              />
                            ) : (
                              <Icon
                                icon="iconamoon:notification-off"
                                width="1.2em"
                                height="1.2em"
                              />
                            )}
                          </button>

                          <button
                            className="rounded "
                            style={{
                              color: organizationState[0]?.secondaryColor,
                            }}
                            onClick={() => handleDiscarded(item?.id)}
                          >
                            <RiDeleteBin6Line size={19}/>
                          </button>
                        </div>
                      )}
                    </div>
                  </li>
                );
              })}
            </ul>

            {threadItems.length > 10 && (
              <>
                <div className="showmorebtn flex items-center p-1 rounded-lg transition-colors duration-200">
                  <div className="icon mr-2">
                    {showMore ? <IoIosArrowUp /> : <IoIosArrowDown />}
                  </div>
                  <div>
                    <button
                      className="text-slate-500 focus:outline-none focus:ring-none rounded text-sm"
                      onClick={() => setShowMore(!showMore)}
                    >
                      {showMore ? "Show Less" : "More"}
                    </button>
                  </div>
                </div>
              </>
            )}
            <div className="flex items-center gap-2 py-1 rounded hover:bg-slate-200 cursor-pointer" onClick={() => {
                  navigate(`/dashboard`);
                }}>
              <div
                className="flex items-center justify-center p-1 rounded-md"
                style={{ backgroundColor: "#e5e7eb" }}
              >
                <IoAdd />
              </div>
              <button
                className="text-center text-sm "
                
              >
                Create new Thread
              </button>
            </div>
          </>
        }
      </>
    ) : tabType === "dms" ? (
      <>
        <ul className="space-y-2 max-h-[30rem]  overflow-y-auto overflow-x-hidden">
          {/* {moreDmsDislay?.length === 0 && (
            // <div className="flex items-center gap-2 py-1 rounded hover:bg-slate-200">
            //   <div className="flex items-center justify-center p-2 rounded-md" style={{backgroundColor:"#e5e7eb"}}><IoAdd/></div>
            //   <button
            //     className="text-center text-sm "
            //     onClick={() => {
            //       navigate(`/account`, { state: { tab: "Members" } });
            //     }}
            //   >
            //     Create new DM 
            //   </button>
            // </div>
            <div className="flex items-center gap-2 p-2 rounded">
              <p className="text-sm text-pricingText text-center w-full   ">
                No DMs Yet!
              </p>
            </div>
          )} */}
          {moreDmsDislay?.slice(0, 10)?.map((item, index) => {
            return (
              <li
                key={index}
                className={`p-1 rounded-lg transition-colors duration-200 hover:bg-slate-200`}
                style={{
                  background: isItemActive(item.id)
                    ? organizationState[0]?.secondaryColor
                    : "",
                }}
              >
                <Link
                  to={`${linkPrefix1}?id=${item.id}&tabType=dashboard`}
                  onClick={(e) => {
                    handleItemClick(item.id);
                    if (item.notification > 0) {
                      e.preventDefault();
                      resetOrgMemberNotification(item.id).then(() => {
                        navigate(
                          `${linkPrefix1}?id=${item.id}&tabType=dashboard`
                        );
                      });
                    }
                  }}
                  style={{
                    color: isItemActive(item.id) ? "white" : "black",
                  }}
                  className={`text-sm flex  items-center   truncate w-full  flex-grow text-landingText capitalize  font-normal  ${
                    item.notification > 0 && "text-gray-900 font-semibold"
                  }`}
                >
                  <div className="relative mr-2">
                    {/* {item.userPictureUrl ? ( */}
                    <img
                      src={item.userPictureUrl || userPicture}
                      alt="profile"
                      className="rounded-full"
                      style={{
                        width: "22px",
                        height: "22px",
                        objectFit: "cover",
                      }}
                    />

                    <span
                      className={`absolute bottom-0 right-0 w-2 h-2 rounded-full ${
                        item.userStatus === "online"
                          ? "bg-green-500"
                          : "bg-gray-500"
                      }`}
                    ></span>
                  </div>
                  <div className="truncate max-w-[70%]">
                    <span>{item.name}</span>
                  </div>
                  {item.notification > 0 && (
                    <div className="bg-red-500 text-white text-xs font-normal w-5 h-5 rounded-full ml-2 text-center flex items-center justify-center">
                      {item.notification > 4 ? "4+" : item.notification}
                    </div>
                  )}
                </Link>
              </li>
            );
          })}
        </ul>

        {items?.length > 10 && (
          <>
            <div className="showmorebtn flex items-center p-1 rounded-lg transition-colors duration-200">
              <div className="icon mr-2">
                {showMore ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </div>
              <div>
                <button
                  onClick={() => setShowAllDms((prevState) => !prevState)}
                  className="text-slate-500 focus:outline-none focus:ring-none rounded text-sm"
                >
                  {showAllDms ? "Show Less" : "More"}
                </button>
              </div>
            </div>
          </>
        )}
        <div className="flex items-center gap-2 py-1 rounded hover:bg-slate-200 cursor-pointer" onClick={(e) => {
              e.stopPropagation();
              setStartNewChat(true);
            }}>
          <div
            className="flex items-center justify-center p-1 rounded-md"
            style={{ backgroundColor: "#e5e7eb" }}
          >
            <IoAdd />
          </div>
          <button
            className="text-center text-sm"
            
          >
            Invite New Member
          </button>
        </div>
      </>
    ) : (
      <div>
        <ul className="space-y-2 max-h-[30rem] hover:overflow-y-auto overflow-hidden">
          {moreSpacesDislay.map((item, index) => {
            return (
              <li
                key={index}
                className={`text-sm flex justify-between items-center p-1 rounded-lg  transition-colors duration-200 hover:bg-slate-200 `}
                style={{
                  background: isItemActive(item.id)
                    ? organizationState[0]?.secondaryColor
                    : "",
                }}
              >
                <div
                  className="relative group w-full"
                  onMouseEnter={() => setTeamShowButtons(index)}
                  onMouseLeave={() => setTeamShowButtons(false)}
                >
                  <div className="flex items-center">
                    <Icon
                      style={{
                        color: isItemActive(item.id) ? "white" : "black",
                      }}
                      icon={
                        item.teamType === "public"
                          ? "solar:hashtag-linear"
                          : "mdi:lock"
                      }
                      width="14px"
                      height="14px"
                      className="text-pricingText mr-2"
                    />
                    <Link
                      onClick={() => {
                        resetNotification(item.id);
                        handleItemClick(item.id);
                      }}
                      to={`${linkPrefix1}?id=${item.id}&tabType=dashboard`}
                      style={{
                        color: isItemActive(item.id) ? "white" : "black",
                      }}
                      className={`truncate w-52  flex-grow text-landingText capitalize  font-normal  ${
                        item.notification > 0 && "text-gray-900 font-semibold"
                      }`}
                    >
                      {item.name}
                    </Link>
                    {item.notification > 0 && (
                      <p className="bg-red-500 text-white text-xs font-bold w-5 h-5 p-[0.5px] rounded-full ml-2 text-center">
                        {item.notification}
                      </p>
                    )}
                  </div>
                  {teamShowButtons === index && (
                    <div
                      className={`border absolute right-5 top-1/2 -translate-y-1/2 flex space-x-2 rounded-md px-2 py-1 transition-opacity bg-white duration-200 scale-90`}
                    >
                      <button
                        className="rounded "
                        style={{
                          color: organizationState[0]?.secondaryColor,
                        }}
                        onClick={() => handleChangeNotification(item)}
                      >
                        {item.members.map((member) => {
                          if (member.email === user?.email) {
                            return member.notificationOn ? (
                              <Icon
                                icon="basil:notification-on-outline"
                                width="1.2em"
                                height="1.2em"
                              />
                            ) : (
                              <Icon
                                icon="iconamoon:notification-off"
                                width="1.2em"
                                height="1.2em"
                              />
                            );
                          }
                        })}
                      </button>
                    </div>
                  )}
                </div>
              </li>
            );
          })}
        </ul>
        {items?.length > 10 && (
          <>
            <div className="showmorebtn flex items-center p-1 rounded-lg transition-colors duration-200">
              <div className="icon mr-2">
                {showMore ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </div>
              <div>
                <button
                  onClick={() => setShowAllSpaces((prevState) => !prevState)}
                  className="text-slate-500 focus:outline-none focus:ring-none rounded text-sm"
                >
                  {showAllSpaces ? "Show Less" : "More"}
                </button>
              </div>
            </div>
          </>
        )}
        <div className="flex items-center gap-2 py-1 rounded hover:bg-slate-200 cursor-pointer" onClick={() => {
              setTeamModal(true);
            }}>
          <div
            className="flex items-center justify-center p-1 rounded-md"
            style={{ backgroundColor: "#e5e7eb" }}
          >
            <IoAdd />
          </div>
          <button
            className="text-center text-sm"
            
          >
            Create new space
          </button>
        </div>
      </div>
    );
  };

  const SidebarTab = ({
    text,
    tab,
    children,
    handleTabClick,
    notificationCount,
    isLocked,
    showPlus,
  }) => {
    return (
      <li>
        <button
          className={`inline-flex items-center p-2 w-full ${
            isLocked ? "cursor-not-allowed opacity-50" : ""
          }`}
          onClick={() => !isLocked && handleTabClick(tab)}
        >
          <>
            <p className="font-[600] text-black truncate max-w-[70%]">{text}</p>
            {notificationCount > 0 && (
              <div className="bg-red-500 text-white text-sm font-bold w-5 h-5 p-[0.5px] rounded-full ml-4 flex items-center justify-center">
                {notificationCount > 4 ? "4+" : notificationCount}
              </div>
            )}
            <Icon
              icon={
                isLocked
                  ? "heroicons:lock-closed"
                  : expandedTab.includes(tab)
                  ? "ep:arrow-up-bold"
                  : "ep:arrow-down-bold"
              }
              className="ml-auto text-secondary"
            />
          </>
        </button>
        {!isLocked && expandedTab.includes(tab) && children}
      </li>
    );
  };

  const recentChats = chatWithLimitState?.history?.map((chat) => {
    const data = {
      id: chat.threadId,
      name: chat.chatName,
      type: chat.type,
      createdAt: chat.createdAt,
      starred: chat.starred,
      notificationOn: chat.notificationOn,
    };
    if (chat.type === "thread") {
      data["notification"] = chat.notification;
      data.tag = chat.tag;
    }
    return data;
  });

  let teams = [];
  teamState
    ?.filter((team) =>
      team.members.some((member) => member.email === user?.email)
    )
    .forEach((team) => {
      const data = {
        id: team.teamId,
        name: team.teamName,
        notification: team.notification,
        teamType: team.teamType,
        notificationOn: team.notificationOn,
        members: team.members,
      };
      teams.push(data);
    });

  const [closeSideBar, setCloseSideBar] = useState(false);
  const renderHome = () => (
    <ul
      className="space-y-2 px-3 py-1 hover:overflow-auto overflow-hidden"
      style={{ height: "calc(100vh - 56px)" }}
    >
      <li>
        <div className="flex items-center justify-between py-5 border-b-2">
          <p className="text-xl font-bold ">Home</p>
          <button onClick={() => openDashboard()}>
            <Icon
              icon="heroicons:pencil-square"
              width="1.3em"
              height="1.3em"
              className="text-secondary"
            />
          </button>
        </div>
      </li>

      <SidebarTab
        icon="ph:chats-circle-bold"
        text="Starred"
        tab="starred"
        isExpanded={expandedTab.includes("starred")}
        handleTabClick={handleTabClick}
        notificationCount={starredNotification}
      >
        <TabContent
          items={recentChats}
          linkPrefix1="/answer-search"
          linkPrefix2="/thread"
          tabType="starred"
          openModal={openModal}
          setCloseSideBar={setCloseSideBar}
          closeSideBar={closeSideBar}
          isLoading={loadingTab.includes("starred")}
        />
      </SidebarTab>
      <SidebarTab
        icon="ph:chats-circle-bold"
        text="Threads"
        tab="threads"
        isExpanded={expandedTab.includes("threads")}
        handleTabClick={handleTabClick}
        notificationCount={threadNotification}
      >
        <TabContent
          items={recentChats}
          linkPrefix1="/answer-search"
          linkPrefix2="/chat-history"
          tabType="threads"
          openModal={openModal}
          setCloseSideBar={setCloseSideBar}
          closeSideBar={closeSideBar}
          isLoading={loadingTab.includes("threads")}
        />
      </SidebarTab>
      <SidebarTab
        icon="hugeicons:add-team"
        text="Spaces"
        tab="spaces"
        isExpanded={expandedTab.includes("spaces")}
        handleTabClick={handleTabClick}
      >
        <TabContent
          items={teams}
          linkPrefix1="/team"
          linkPrefix2="/teams"
          tabType="spaces"
          openModal={openModal}
          isLoading={loadingTab.includes("spaces")}
        />
      </SidebarTab>
      <SidebarTab
        icon="hugeicons:add-team"
        text="Direct Messages"
        tab="dms"
        isExpanded={expandedTab.includes("dms")}
        handleTabClick={handleTabClick}
        showPlus={true}
      >
        <TabContent
          items={DirectMessages}
          linkPrefix1="/dm"
          linkPrefix2="/dms"
          tabType="dms"
          openModal={openModal}
          isLoading={loadingTab.includes("dms")}
        />
      </SidebarTab>
    </ul>
  );

  const renderContent = () => {
    switch (dashboardActiveTabState) {
      case "Threads":
        return (
          <SidebarRenderThreads
            isItemActive={isItemActive}
            setActiveItemId={setActiveItemId}
          />
        );
      case "DMs":
        return (
          <SidebarRenderDMs
            isItemActive={isItemActive}
            setActiveItemId={setActiveItemId}
          />
        );
      case "Files":
        return (
          <SidebarRenderFolders
            setActiveItemId={setActiveItemId}
            isItemActive={isItemActive}
          />
        );
      case "Home":
        return renderHome();
      case "Space":
        return (
          <SidebarRenderSpace
            setActiveItemId={setActiveItemId}
            isItemActive={isItemActive}
          />
        );
      case "Starred":
        return (
          <SidebarRenderStarred
            setActiveItemId={setActiveItemId}
            isItemActive={isItemActive}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div
        className={`sidebar ${closeSideBar ? "hidden" : "flex"} ${
          !dashboard ? "flex" : "hidden"
        } md:flex`}
      >
        <div
          className={`md:flex flex-col header-height xl:w-72 lg:w-60 md:w-48 w-full bg-sidebarBgColor justify-between transition-width duration-300 z-10`}
        >
          <div className="">{renderContent()}</div>
        </div>
      </div>
      {startNewChat && (
        <StartDM
          setStartNewChat={setStartNewChat}
          newDMList={newDMList}
          setNewDMList={setNewDMList}
        />
      )}
      <TeamModal
        teamModal={teamModal}
        setTeamModal={setTeamModal}
        initialTeam={initialTeam}
        setInitialTeam={setInitialTeam}
      />
      <WebChatModel
        isOpenWebModal={isOpenWebModal}
        setIsOpenWebModal={setIsOpenWebModal}
      />
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        confirmAction={confirmDiscardedThread}
        label={"Discard Thread"}
        description={
          "Are you sure you want to discard this thread? This action cannot be undone and the thread will be permanently removed from your account."
        }
        confirmText={isThreadDiscardLoading ? (<span className="loader"></span>) :"Confirm"}
        cancelText={"Cancel"}
      />
    </>
  );
}

export default Sidebar;

const StartDM = ({ setStartNewChat, newDMList, setNewDMList }) => {
  const dispatch = useAppDispatch();
  const [member, setMember] = useState("");
  const { organizationState } = useAppSelector((state) => ({
    organizationState: state.organization.organization,
  }));

  const navigate = useNavigate();

  return (
    <div
      className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-50 bg-black bg-opacity-50"
      onClick={() => setStartNewChat(false)}
    >
      <div
        className="bg-white rounded-lg shadow-lg flex flex-col items-center px-8 py-6 z-51"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between items-center w-80">
          <h1 className="text-lg font-semibold text-gray-800">
            Start a new Chat
          </h1>
          <button
            onClick={() => setStartNewChat(false)}
            className="text-gray-500"
          >
            {" "}
            <RxCross2 />{" "}
          </button>
        </div>
        <select
          value={member}
          onChange={(e) => setMember(e.target.value)}
          className="w-80 my-4 border border-gray-300 rounded-md py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
        >
          <option value={""} style={{ display: "none" }}>
            Select a member
          </option>
          {newDMList.length === 0 && (
            <option value="none" disabled>
              There are no more members to chat with
            </option>
          )}
          {newDMList?.map((member) => (
            <option key={member.id} value={member.id} className="py-2 px-3">
              {member.name}
            </option>
          ))}
        </select>

        <button
          type="submit"
          className="w-64 my-3 text-white font-medium py-2 px-4 rounded-md transition duration-200 hover:opacity-90"
          style={{ backgroundColor: organizationState[0]?.primaryColor }}
          onClick={() => {
            if (member === "") return;
            navigate(`/dm?id=${member}&tabType=dashboard`);
            setStartNewChat(false);
            const newChat = newDMList.find((item) => item.id === member);
            dispatch(addNewDM(newChat));
            setNewDMList((prevState) =>
              prevState.filter((item) => item.id !== member)
            );
          }}
        >
          Start DM with selected User
        </button>
      </div>
    </div>
  );
};
