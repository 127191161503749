import React, { useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToMarkdown from 'draftjs-to-markdown';
import { stateFromMarkdown } from 'draft-js-import-markdown';
import { MdSend, MdClose } from "react-icons/md";
import { useAppSelector } from "../store";

const MessageEditor2 = ({ initialMessage, onSave, onCancel }) => {
  const { organizationState } = useAppSelector((state) => ({
    organizationState: state.organization.organization,
  }));

  const [editorState, setEditorState] = useState(() => {
    try {
      const contentState = stateFromMarkdown(initialMessage || '');
      return EditorState.createWithContent(contentState);
    } catch (error) {
      const contentState = ContentState.createFromText(initialMessage || '');
      return EditorState.createWithContent(contentState);
    }
  });

  const handleSave = () => {
    const contentState = editorState.getCurrentContent();
    const rawContent = convertToRaw(contentState);
    const markdown = draftToMarkdown(rawContent, {
      preserveNewlines: true,
      preserveWhitespace: true
    });
    onSave(markdown);
  };

  return (
    <div className="ml-12 mt-[-15px]">
      <div className="border rounded-md relative">
        <div className="flex flex-col">
          <Editor
            editorState={editorState}
            onEditorStateChange={setEditorState}
            toolbarClassName="border-b"
            wrapperClassName="w-full"
            editorClassName="px-4 py-2 min-h-[60px] mb-6"
            toolbar={{
              options: ['inline', 'list'],
              inline: {
                options: ["bold", "italic"],
                className: "inline-style",
                bold: { icon: "/bold.svg", className: "bold-button" },
              },
              list: {
                options: ["unordered", "ordered"],
                className: "inline-style",
              },
            }}
          />
          <div className="absolute bottom-2 right-2 z-10 flex gap-2 bg-white">
            <button
              onClick={handleSave}
              className="px-3 py-1 bg-blue-500 text-white text-sm rounded hover:bg-blue-600"
              style={{ backgroundColor: organizationState[0]?.primaryColor }}
            >
              <MdSend size={20}/>
            </button>
            <button
              onClick={onCancel}
              className="px-3 py-1 bg-gray-200 text-white text-sm rounded hover:bg-gray-300"
              style={{ backgroundColor: organizationState[0]?.primaryColor }}
            >
              <MdClose size={20}/>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageEditor2;